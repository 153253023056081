import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

export type Account = {
  __typename?: 'Account';
  clientId: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isSuspended: Scalars['Boolean'];
  phone: Scalars['String'];
  pin: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Answer = {
  __typename?: 'Answer';
  createdAt: Scalars['ISO8601DateTime'];
  eproQuestion: Question;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  value: Scalars['JSON'];
};

export type Artifact = {
  __typename?: 'Artifact';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isPublished: Scalars['Boolean'];
  nameEn?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  no: Scalars['String'];
  persisted: Scalars['Boolean'];
  trial?: Maybe<Trial>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['String'];
};

export enum Authority {
  /** read */
  Read = 'read',
  /** write */
  Write = 'write'
}

export type CompletedQuestionnaire = {
  __typename?: 'CompletedQuestionnaire';
  createdAt: Scalars['ISO8601DateTime'];
  eproAccount: Account;
  eproAnswers: Array<Answer>;
  eproSchedule: Schedule;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type CreateCompletedQuestionnaireInput = {
  eproQuestionId: Scalars['ID'];
  value: Scalars['JSON'];
};

/** Autogenerated return type of CreateCompletedQuestionnaire */
export type CreateCompletedQuestionnairePayload = {
  __typename?: 'CreateCompletedQuestionnairePayload';
  completedQuestionnaire: CompletedQuestionnaire;
};

/** Autogenerated return type of CreateQuestionnaire */
export type CreateQuestionnairePayload = {
  __typename?: 'CreateQuestionnairePayload';
  errors: Array<Scalars['String']>;
  questionnaire: Questionnaire;
};

/** Autogenerated return type of DeleteDocument */
export type DeleteDocumentPayload = {
  __typename?: 'DeleteDocumentPayload';
  errors: Array<Scalars['String']>;
};

/** Autogenerated return type of DeleteQuestionnaire */
export type DeleteQuestionnairePayload = {
  __typename?: 'DeleteQuestionnairePayload';
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteReminder */
export type DeleteReminderPayload = {
  __typename?: 'DeleteReminderPayload';
  id: Scalars['ID'];
};

export type Document = {
  __typename?: 'Document';
  artifactNo: Scalars['String'];
  domain: Scalars['String'];
  etag: Scalars['String'];
  key: Scalars['String'];
  lastModifiedAt: Scalars['ISO8601DateTime'];
  name: Scalars['String'];
  parent: Scalars['String'];
  path: Scalars['String'];
  trial: Scalars['String'];
};

export type DocumentFile = {
  __typename?: 'DocumentFile';
  url: Scalars['String'];
};



export type IncompleteQuestionnaire = {
  __typename?: 'IncompleteQuestionnaire';
  begin: Scalars['ISO8601Date'];
  createdAt: Scalars['ISO8601DateTime'];
  end?: Maybe<Scalars['ISO8601Date']>;
  eproAccount: Account;
  eproSchedule: Schedule;
  id: Scalars['ID'];
  snapshot: Scalars['JSON'];
  updatedAt: Scalars['ISO8601DateTime'];
};


export enum Locale {
  /** en */
  En = 'en',
  /** ja */
  Ja = 'ja'
}

export type Mutation = {
  __typename?: 'Mutation';
  createEproCompletedQuestionnaire: CreateCompletedQuestionnairePayload;
  createEproQuestionnaire: CreateQuestionnairePayload;
  deleteDocument?: Maybe<DeleteDocumentPayload>;
  deleteEproQuestionnaire: DeleteQuestionnairePayload;
  deleteEproReminder: DeleteReminderPayload;
  publishArtifact?: Maybe<PublishArtifactPayload>;
  updateEproAccount: UpdateAccountPayload;
  updateEproQuestionnaire: UpdateQuestionnairePayload;
  updateEproSchedules: UpdateSchedulesPayload;
  uploadDocument?: Maybe<UploadDocumentPayload>;
  upsertEproReminder: UpsertReminderPayload;
};


export type MutationCreateEproCompletedQuestionnaireArgs = {
  clientId: Scalars['String'];
  incompleteId: Scalars['ID'];
  input: Array<CreateCompletedQuestionnaireInput>;
  pin: Scalars['String'];
};


export type MutationCreateEproQuestionnaireArgs = {
  trial: Scalars['String'];
};


export type MutationDeleteDocumentArgs = {
  artifact: Scalars['String'];
  path: Scalars['String'];
  trial: Scalars['String'];
};


export type MutationDeleteEproQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEproReminderArgs = {
  id: Scalars['ID'];
};


export type MutationPublishArtifactArgs = {
  artifact: Scalars['String'];
  publish: Scalars['Boolean'];
  trial: Scalars['String'];
};


export type MutationUpdateEproAccountArgs = {
  clientId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pin: Scalars['String'];
};


export type MutationUpdateEproQuestionnaireArgs = {
  input: UpdateQuestionnaireInput;
};


export type MutationUpdateEproSchedulesArgs = {
  input: Array<UpdateScheduleInput>;
  trial: Scalars['String'];
};


export type MutationUploadDocumentArgs = {
  artifact: Scalars['String'];
  path: Scalars['String'];
  trial: Scalars['String'];
};


export type MutationUpsertEproReminderArgs = {
  input: UpsertReminderInput;
  trial: Scalars['String'];
};

export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['Int'];
  isGeneralPurpose: Scalars['Boolean'];
  name: Scalars['String'];
  properName: Scalars['String'];
  trials: Array<Trial>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of PublishArtifact */
export type PublishArtifactPayload = {
  __typename?: 'PublishArtifactPayload';
  artifact: Artifact;
  errors: Array<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** アクセス可能な試験情報の一覧を取得する */
  accessibleTrials: Array<Trial>;
  /** 指定した試験のTMF Artifactの一覧を取得する */
  artifacts: Array<Artifact>;
  /** 指定した試験の指定したTMF Artifactのファイル一覧を取得する */
  documents: Array<Document>;
  /** 指定した試験の指定したファイルを取得する */
  downloadDocument: DocumentFile;
  /** eproアカウント情報を取得する */
  eproAccount?: Maybe<Account>;
  /** 回答済みの質問を取得する */
  eproCompletedQuestionnaire: CompletedQuestionnaire;
  /** 回答済みの質問を取得する */
  eproCompletedQuestionnaires: Array<CompletedQuestionnaire>;
  /** 未回答の質問を取得する */
  eproIncompleteQuestionnaire: IncompleteQuestionnaire;
  /** 未回答の質問を取得する */
  eproIncompleteQuestionnaires: Array<IncompleteQuestionnaire>;
  /** eProのアンケートを取得する */
  eproQuestionnaire: Questionnaire;
  /** eProのアンケートを取得する */
  eproQuestionnaires: Array<Questionnaire>;
  /** eProの基準日を取得する */
  eproReferenceDates: Array<ReferenceDate>;
  /** eProのリマインドを取得する */
  eproReminder: Reminder;
  /** eProのスケジュールを取得する */
  eproSchedules: Array<Schedule>;
  /** アクセス可能な試験情報を取得する */
  trial: Trial;
  /** 試験の権限を取得する */
  trialAuthority: TrialAuthority;
  /** アクセス可能な試験情報の一覧を取得する */
  trials: Array<Trial>;
};


export type QueryArtifactsArgs = {
  trial: Scalars['String'];
};


export type QueryDocumentsArgs = {
  artifact: Scalars['String'];
  trial: Scalars['String'];
};


export type QueryDownloadDocumentArgs = {
  artifact: Scalars['String'];
  key: Scalars['String'];
  trial: Scalars['String'];
};


export type QueryEproAccountArgs = {
  clientId: Scalars['String'];
  pin: Scalars['String'];
};


export type QueryEproCompletedQuestionnaireArgs = {
  clientId: Scalars['String'];
  id: Scalars['ID'];
  pin: Scalars['String'];
};


export type QueryEproCompletedQuestionnairesArgs = {
  clientId: Scalars['String'];
  pin: Scalars['String'];
};


export type QueryEproIncompleteQuestionnaireArgs = {
  clientId: Scalars['String'];
  id: Scalars['ID'];
  pin: Scalars['String'];
};


export type QueryEproIncompleteQuestionnairesArgs = {
  clientId: Scalars['String'];
  pin: Scalars['String'];
};


export type QueryEproQuestionnaireArgs = {
  id: Scalars['ID'];
  trial: Scalars['String'];
};


export type QueryEproQuestionnairesArgs = {
  trial: Scalars['String'];
};


export type QueryEproReferenceDatesArgs = {
  trial: Scalars['String'];
};


export type QueryEproReminderArgs = {
  id: Scalars['ID'];
  trial: Scalars['String'];
};


export type QueryEproSchedulesArgs = {
  trial: Scalars['String'];
};


export type QueryTrialArgs = {
  name: Scalars['String'];
};


export type QueryTrialAuthorityArgs = {
  trial: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  config: Scalars['JSON'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  label: Scalars['String'];
  seq: Scalars['Int'];
  type: QuestionKind;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type QuestionInput = {
  config: Scalars['JSON'];
  id?: Maybe<Scalars['ID']>;
  label: Scalars['String'];
  seq: Scalars['Int'];
  type: QuestionKind;
};

export enum QuestionKind {
  /** check */
  Check = 'check',
  /** radio */
  Radio = 'radio',
  /** slider */
  Slider = 'slider',
  /** text */
  Text = 'text'
}

export type Questionnaire = {
  __typename?: 'Questionnaire';
  canEdit: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  eproQuestions: Array<Question>;
  id: Scalars['ID'];
  label: Scalars['String'];
  trial: Trial;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum Reference {
  /** begin */
  Begin = 'begin',
  /** end */
  End = 'end'
}

export type ReferenceDate = {
  __typename?: 'ReferenceDate';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isYearly: Scalars['Boolean'];
  label: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Reminder = {
  __typename?: 'Reminder';
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  days: Scalars['Int'];
  eproSchedule: Schedule;
  id: Scalars['ID'];
  referenceType: Reference;
  sendingTime: Scalars['ISO8601DateTime'];
  subject?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum Role {
  /** admin */
  Admin = 'admin',
  /** dm */
  Dm = 'dm',
  /** monitor */
  Monitor = 'monitor',
  /** user */
  User = 'user'
}

export type Schedule = {
  __typename?: 'Schedule';
  canEdit: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  days: Scalars['Int'];
  eproQuestionnaire: Questionnaire;
  eproReferenceDate: ReferenceDate;
  eproReminders: Array<Reminder>;
  id: Scalars['ID'];
  periodDays?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Trial = {
  __typename?: 'Trial';
  aeCommitteeMembers: Array<User>;
  closedOn?: Maybe<Scalars['ISO8601Date']>;
  closedRegistrationOn?: Maybe<Scalars['ISO8601Date']>;
  committeeMembers: Array<User>;
  createdAt: Scalars['ISO8601DateTime'];
  dataManagers: Array<User>;
  eproQuestionnaires: Array<Questionnaire>;
  eproReferenceDates: Array<ReferenceDate>;
  eproSchedules: Array<Schedule>;
  id: Scalars['ID'];
  isEproAvailable: Scalars['Boolean'];
  isObservationStudy: Scalars['Boolean'];
  isRegistrational: Scalars['Boolean'];
  isSuspended: Scalars['Boolean'];
  labUsers: Array<User>;
  monitors: Array<User>;
  name: Scalars['String'];
  openedOn?: Maybe<Scalars['ISO8601Date']>;
  organization: Organization;
  primaryInvestigator?: Maybe<User>;
  properName: Scalars['String'];
  registryIdentifier?: Maybe<Scalars['String']>;
  secretariats: Array<User>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TrialAuthority = {
  __typename?: 'TrialAuthority';
  authority?: Maybe<Authority>;
  trial: Trial;
};

/** Autogenerated return type of UpdateAccount */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  account: Account;
};

export type UpdateQuestionnaireInput = {
  eproQuestions: Array<QuestionInput>;
  id: Scalars['ID'];
  label: Scalars['String'];
};

/** Autogenerated return type of UpdateQuestionnaire */
export type UpdateQuestionnairePayload = {
  __typename?: 'UpdateQuestionnairePayload';
  questionnaire: Questionnaire;
};

export type UpdateScheduleInput = {
  days: Scalars['Int'];
  eproQuestionnaireId: Scalars['ID'];
  eproReferenceDateId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  periodDays?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateSchedules */
export type UpdateSchedulesPayload = {
  __typename?: 'UpdateSchedulesPayload';
  schedules: Array<Schedule>;
};

/** Autogenerated return type of UploadDocument */
export type UploadDocumentPayload = {
  __typename?: 'UploadDocumentPayload';
  document: Document;
  errors: Array<Scalars['String']>;
};

export type UpsertReminderInput = {
  body: Scalars['String'];
  days: Scalars['Int'];
  eproScheduleId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  referenceType: Reference;
  subject: Scalars['String'];
};

/** Autogenerated return type of UpsertReminder */
export type UpsertReminderPayload = {
  __typename?: 'UpsertReminderPayload';
  reminder: Reminder;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  firstnameEn: Scalars['String'];
  id: Scalars['ID'];
  isDoctor: Scalars['Boolean'];
  isEmpowered: Scalars['Boolean'];
  isSuspended: Scalars['Boolean'];
  lastname: Scalars['String'];
  lastnameEn: Scalars['String'];
  locale: Locale;
  role: Role;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type CreateQuestionnaireMutationVariables = Exact<{
  trial: Scalars['String'];
}>;


export type CreateQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { createEproQuestionnaire: (
    { __typename?: 'CreateQuestionnairePayload' }
    & { questionnaire: (
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'label' | 'canEdit'>
    ) }
  ) }
);

export type UpdateQuestionnaireMutationVariables = Exact<{
  input: UpdateQuestionnaireInput;
}>;


export type UpdateQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { updateEproQuestionnaire: (
    { __typename?: 'UpdateQuestionnairePayload' }
    & { questionnaire: (
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'label' | 'canEdit'>
      & { eproQuestions: Array<(
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'seq' | 'label' | 'type' | 'config'>
      )> }
    ) }
  ) }
);

export type DeleteQuestionnaireMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { deleteEproQuestionnaire: (
    { __typename?: 'DeleteQuestionnairePayload' }
    & Pick<DeleteQuestionnairePayload, 'id'>
  ) }
);

export type UpdateSchedulesMutationVariables = Exact<{
  trial: Scalars['String'];
  input: Array<UpdateScheduleInput> | UpdateScheduleInput;
}>;


export type UpdateSchedulesMutation = (
  { __typename?: 'Mutation' }
  & { updateEproSchedules: (
    { __typename?: 'UpdateSchedulesPayload' }
    & { schedules: Array<(
      { __typename?: 'Schedule' }
      & Pick<Schedule, 'id' | 'days' | 'periodDays' | 'canEdit'>
      & { eproQuestionnaire: (
        { __typename?: 'Questionnaire' }
        & Pick<Questionnaire, 'id'>
      ), eproReferenceDate: (
        { __typename?: 'ReferenceDate' }
        & Pick<ReferenceDate, 'id'>
      ) }
    )> }
  ) }
);

export type UpsertReminderMutationVariables = Exact<{
  trial: Scalars['String'];
  input: UpsertReminderInput;
}>;


export type UpsertReminderMutation = (
  { __typename?: 'Mutation' }
  & { upsertEproReminder: (
    { __typename?: 'UpsertReminderPayload' }
    & { reminder: (
      { __typename?: 'Reminder' }
      & Pick<Reminder, 'id' | 'referenceType' | 'days' | 'subject' | 'body'>
    ) }
  ) }
);

export type DeleteReminderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteReminderMutation = (
  { __typename?: 'Mutation' }
  & { deleteEproReminder: (
    { __typename?: 'DeleteReminderPayload' }
    & Pick<DeleteReminderPayload, 'id'>
  ) }
);

export type CreateCompletedQuestionnaireMutationVariables = Exact<{
  clientId: Scalars['String'];
  pin: Scalars['String'];
  incompleteId: Scalars['ID'];
  input: Array<CreateCompletedQuestionnaireInput> | CreateCompletedQuestionnaireInput;
}>;


export type CreateCompletedQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { createEproCompletedQuestionnaire: (
    { __typename?: 'CreateCompletedQuestionnairePayload' }
    & { completedQuestionnaire: (
      { __typename?: 'CompletedQuestionnaire' }
      & Pick<CompletedQuestionnaire, 'id'>
    ) }
  ) }
);

export type UpdateAccountMutationVariables = Exact<{
  clientId: Scalars['String'];
  pin: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
}>;


export type UpdateAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateEproAccount: (
    { __typename?: 'UpdateAccountPayload' }
    & { account: (
      { __typename?: 'Account' }
      & Pick<Account, 'id'>
    ) }
  ) }
);

export type TrialsQueryVariables = Exact<{ [key: string]: never; }>;


export type TrialsQuery = (
  { __typename?: 'Query' }
  & { trials: Array<(
    { __typename?: 'Trial' }
    & Pick<Trial, 'id' | 'name' | 'isEproAvailable'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    ) }
  )> }
);

export type TrialQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type TrialQuery = (
  { __typename?: 'Query' }
  & { trial: (
    { __typename?: 'Trial' }
    & Pick<Trial, 'id' | 'name'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    ), eproQuestionnaires: Array<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'label' | 'canEdit'>
    )> }
  ) }
);

export type QuestionnaireQueryVariables = Exact<{
  trial: Scalars['String'];
  id: Scalars['ID'];
}>;


export type QuestionnaireQuery = (
  { __typename?: 'Query' }
  & { eproQuestionnaire: (
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'label' | 'canEdit'>
    & { eproQuestions: Array<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'seq' | 'label' | 'type' | 'config'>
    )> }
  ) }
);

export type QuestionnairesQueryVariables = Exact<{
  trial: Scalars['String'];
}>;


export type QuestionnairesQuery = (
  { __typename?: 'Query' }
  & { eproQuestionnaires: Array<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'label' | 'canEdit'>
  )> }
);

export type SchedulerQueryVariables = Exact<{
  trial: Scalars['String'];
}>;


export type SchedulerQuery = (
  { __typename?: 'Query' }
  & { eproReferenceDates: Array<(
    { __typename?: 'ReferenceDate' }
    & Pick<ReferenceDate, 'id' | 'label' | 'isYearly'>
  )>, eproSchedules: Array<(
    { __typename?: 'Schedule' }
    & Pick<Schedule, 'id' | 'canEdit' | 'days' | 'periodDays'>
    & { eproQuestionnaire: (
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id'>
    ), eproReferenceDate: (
      { __typename?: 'ReferenceDate' }
      & Pick<ReferenceDate, 'id'>
    ) }
  )>, eproQuestionnaires: Array<(
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id' | 'label'>
  )> }
);

export type RemindersQueryVariables = Exact<{
  trial: Scalars['String'];
}>;


export type RemindersQuery = (
  { __typename?: 'Query' }
  & { eproSchedules: Array<(
    { __typename?: 'Schedule' }
    & Pick<Schedule, 'id' | 'days' | 'periodDays'>
    & { eproQuestionnaire: (
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'label'>
    ), eproReferenceDate: (
      { __typename?: 'ReferenceDate' }
      & Pick<ReferenceDate, 'id' | 'label'>
    ), eproReminders: Array<(
      { __typename?: 'Reminder' }
      & Pick<Reminder, 'id' | 'referenceType' | 'days' | 'subject' | 'body'>
    )> }
  )> }
);

export type AccountQueryVariables = Exact<{
  clientId: Scalars['String'];
  pin: Scalars['String'];
}>;


export type AccountQuery = (
  { __typename?: 'Query' }
  & { eproAccount?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'id' | 'clientId' | 'pin' | 'email' | 'phone'>
  )> }
);

export type PatientOverviewQueryVariables = Exact<{
  clientId: Scalars['String'];
  pin: Scalars['String'];
}>;


export type PatientOverviewQuery = (
  { __typename?: 'Query' }
  & { eproIncompleteQuestionnaires: Array<(
    { __typename?: 'IncompleteQuestionnaire' }
    & Pick<IncompleteQuestionnaire, 'id' | 'begin' | 'end'>
    & { eproSchedule: (
      { __typename?: 'Schedule' }
      & { eproQuestionnaire: (
        { __typename?: 'Questionnaire' }
        & Pick<Questionnaire, 'label'>
      ) }
    ) }
  )>, eproCompletedQuestionnaires: Array<(
    { __typename?: 'CompletedQuestionnaire' }
    & Pick<CompletedQuestionnaire, 'id' | 'updatedAt'>
    & { eproSchedule: (
      { __typename?: 'Schedule' }
      & { eproQuestionnaire: (
        { __typename?: 'Questionnaire' }
        & Pick<Questionnaire, 'label'>
      ) }
    ) }
  )> }
);

export type IncompleteQuestionnaireQueryVariables = Exact<{
  clientId: Scalars['String'];
  pin: Scalars['String'];
  id: Scalars['ID'];
}>;


export type IncompleteQuestionnaireQuery = (
  { __typename?: 'Query' }
  & { eproIncompleteQuestionnaire: (
    { __typename?: 'IncompleteQuestionnaire' }
    & Pick<IncompleteQuestionnaire, 'begin' | 'end' | 'snapshot'>
    & { eproSchedule: (
      { __typename?: 'Schedule' }
      & { eproQuestionnaire: (
        { __typename?: 'Questionnaire' }
        & Pick<Questionnaire, 'id' | 'label'>
        & { eproQuestions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'seq' | 'label' | 'type' | 'config'>
        )> }
      ) }
    ) }
  ) }
);

export type CompletedQuestionnaireQueryVariables = Exact<{
  clientId: Scalars['String'];
  pin: Scalars['String'];
  id: Scalars['ID'];
}>;


export type CompletedQuestionnaireQuery = (
  { __typename?: 'Query' }
  & { eproCompletedQuestionnaire: (
    { __typename?: 'CompletedQuestionnaire' }
    & { eproSchedule: (
      { __typename?: 'Schedule' }
      & { eproQuestionnaire: (
        { __typename?: 'Questionnaire' }
        & Pick<Questionnaire, 'id' | 'label'>
        & { eproQuestions: Array<(
          { __typename?: 'Question' }
          & Pick<Question, 'id' | 'seq' | 'label' | 'type' | 'config'>
        )> }
      ) }
    ), eproAnswers: Array<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'id' | 'value'>
      & { eproQuestion: (
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'type'>
      ) }
    )> }
  ) }
);


export const CreateQuestionnaireDocument = gql`
    mutation createQuestionnaire($trial: String!) {
  createEproQuestionnaire(trial: $trial) {
    questionnaire {
      id
      label
      canEdit
    }
  }
}
    `;
export type CreateQuestionnaireMutationFn = Apollo.MutationFunction<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>;

/**
 * __useCreateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useCreateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionnaireMutation, { data, loading, error }] = useCreateQuestionnaireMutation({
 *   variables: {
 *      trial: // value for 'trial'
 *   },
 * });
 */
export function useCreateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>(CreateQuestionnaireDocument, options);
      }
export type CreateQuestionnaireMutationHookResult = ReturnType<typeof useCreateQuestionnaireMutation>;
export type CreateQuestionnaireMutationResult = Apollo.MutationResult<CreateQuestionnaireMutation>;
export type CreateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<CreateQuestionnaireMutation, CreateQuestionnaireMutationVariables>;
export const UpdateQuestionnaireDocument = gql`
    mutation updateQuestionnaire($input: UpdateQuestionnaireInput!) {
  updateEproQuestionnaire(input: $input) {
    questionnaire {
      id
      label
      canEdit
      eproQuestions {
        id
        seq
        label
        type
        config
      }
    }
  }
}
    `;
export type UpdateQuestionnaireMutationFn = Apollo.MutationFunction<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;

/**
 * __useUpdateQuestionnaireMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionnaireMutation, { data, loading, error }] = useUpdateQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>(UpdateQuestionnaireDocument, options);
      }
export type UpdateQuestionnaireMutationHookResult = ReturnType<typeof useUpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationResult = Apollo.MutationResult<UpdateQuestionnaireMutation>;
export type UpdateQuestionnaireMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionnaireMutation, UpdateQuestionnaireMutationVariables>;
export const DeleteQuestionnaireDocument = gql`
    mutation deleteQuestionnaire($id: ID!) {
  deleteEproQuestionnaire(id: $id) {
    id
  }
}
    `;
export type DeleteQuestionnaireMutationFn = Apollo.MutationFunction<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;

/**
 * __useDeleteQuestionnaireMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionnaireMutation, { data, loading, error }] = useDeleteQuestionnaireMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>(DeleteQuestionnaireDocument, options);
      }
export type DeleteQuestionnaireMutationHookResult = ReturnType<typeof useDeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationResult = Apollo.MutationResult<DeleteQuestionnaireMutation>;
export type DeleteQuestionnaireMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionnaireMutation, DeleteQuestionnaireMutationVariables>;
export const UpdateSchedulesDocument = gql`
    mutation updateSchedules($trial: String!, $input: [UpdateScheduleInput!]!) {
  updateEproSchedules(trial: $trial, input: $input) {
    schedules {
      id
      days
      periodDays
      canEdit
      eproQuestionnaire {
        id
      }
      eproReferenceDate {
        id
      }
    }
  }
}
    `;
export type UpdateSchedulesMutationFn = Apollo.MutationFunction<UpdateSchedulesMutation, UpdateSchedulesMutationVariables>;

/**
 * __useUpdateSchedulesMutation__
 *
 * To run a mutation, you first call `useUpdateSchedulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchedulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchedulesMutation, { data, loading, error }] = useUpdateSchedulesMutation({
 *   variables: {
 *      trial: // value for 'trial'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSchedulesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchedulesMutation, UpdateSchedulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchedulesMutation, UpdateSchedulesMutationVariables>(UpdateSchedulesDocument, options);
      }
export type UpdateSchedulesMutationHookResult = ReturnType<typeof useUpdateSchedulesMutation>;
export type UpdateSchedulesMutationResult = Apollo.MutationResult<UpdateSchedulesMutation>;
export type UpdateSchedulesMutationOptions = Apollo.BaseMutationOptions<UpdateSchedulesMutation, UpdateSchedulesMutationVariables>;
export const UpsertReminderDocument = gql`
    mutation upsertReminder($trial: String!, $input: UpsertReminderInput!) {
  upsertEproReminder(trial: $trial, input: $input) {
    reminder {
      id
      referenceType
      days
      subject
      body
    }
  }
}
    `;
export type UpsertReminderMutationFn = Apollo.MutationFunction<UpsertReminderMutation, UpsertReminderMutationVariables>;

/**
 * __useUpsertReminderMutation__
 *
 * To run a mutation, you first call `useUpsertReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertReminderMutation, { data, loading, error }] = useUpsertReminderMutation({
 *   variables: {
 *      trial: // value for 'trial'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertReminderMutation(baseOptions?: Apollo.MutationHookOptions<UpsertReminderMutation, UpsertReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertReminderMutation, UpsertReminderMutationVariables>(UpsertReminderDocument, options);
      }
export type UpsertReminderMutationHookResult = ReturnType<typeof useUpsertReminderMutation>;
export type UpsertReminderMutationResult = Apollo.MutationResult<UpsertReminderMutation>;
export type UpsertReminderMutationOptions = Apollo.BaseMutationOptions<UpsertReminderMutation, UpsertReminderMutationVariables>;
export const DeleteReminderDocument = gql`
    mutation deleteReminder($id: ID!) {
  deleteEproReminder(id: $id) {
    id
  }
}
    `;
export type DeleteReminderMutationFn = Apollo.MutationFunction<DeleteReminderMutation, DeleteReminderMutationVariables>;

/**
 * __useDeleteReminderMutation__
 *
 * To run a mutation, you first call `useDeleteReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReminderMutation, { data, loading, error }] = useDeleteReminderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReminderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReminderMutation, DeleteReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReminderMutation, DeleteReminderMutationVariables>(DeleteReminderDocument, options);
      }
export type DeleteReminderMutationHookResult = ReturnType<typeof useDeleteReminderMutation>;
export type DeleteReminderMutationResult = Apollo.MutationResult<DeleteReminderMutation>;
export type DeleteReminderMutationOptions = Apollo.BaseMutationOptions<DeleteReminderMutation, DeleteReminderMutationVariables>;
export const CreateCompletedQuestionnaireDocument = gql`
    mutation createCompletedQuestionnaire($clientId: String!, $pin: String!, $incompleteId: ID!, $input: [CreateCompletedQuestionnaireInput!]!) {
  createEproCompletedQuestionnaire(
    clientId: $clientId
    pin: $pin
    incompleteId: $incompleteId
    input: $input
  ) {
    completedQuestionnaire {
      id
    }
  }
}
    `;
export type CreateCompletedQuestionnaireMutationFn = Apollo.MutationFunction<CreateCompletedQuestionnaireMutation, CreateCompletedQuestionnaireMutationVariables>;

/**
 * __useCreateCompletedQuestionnaireMutation__
 *
 * To run a mutation, you first call `useCreateCompletedQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompletedQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompletedQuestionnaireMutation, { data, loading, error }] = useCreateCompletedQuestionnaireMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      pin: // value for 'pin'
 *      incompleteId: // value for 'incompleteId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompletedQuestionnaireMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompletedQuestionnaireMutation, CreateCompletedQuestionnaireMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompletedQuestionnaireMutation, CreateCompletedQuestionnaireMutationVariables>(CreateCompletedQuestionnaireDocument, options);
      }
export type CreateCompletedQuestionnaireMutationHookResult = ReturnType<typeof useCreateCompletedQuestionnaireMutation>;
export type CreateCompletedQuestionnaireMutationResult = Apollo.MutationResult<CreateCompletedQuestionnaireMutation>;
export type CreateCompletedQuestionnaireMutationOptions = Apollo.BaseMutationOptions<CreateCompletedQuestionnaireMutation, CreateCompletedQuestionnaireMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation updateAccount($clientId: String!, $pin: String!, $email: String, $phone: String) {
  updateEproAccount(clientId: $clientId, pin: $pin, email: $email, phone: $phone) {
    account {
      id
    }
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      pin: // value for 'pin'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const TrialsDocument = gql`
    query trials {
  trials {
    id
    name
    isEproAvailable
    organization {
      id
      name
    }
  }
}
    `;

/**
 * __useTrialsQuery__
 *
 * To run a query within a React component, call `useTrialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrialsQuery(baseOptions?: Apollo.QueryHookOptions<TrialsQuery, TrialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrialsQuery, TrialsQueryVariables>(TrialsDocument, options);
      }
export function useTrialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrialsQuery, TrialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrialsQuery, TrialsQueryVariables>(TrialsDocument, options);
        }
export type TrialsQueryHookResult = ReturnType<typeof useTrialsQuery>;
export type TrialsLazyQueryHookResult = ReturnType<typeof useTrialsLazyQuery>;
export type TrialsQueryResult = Apollo.QueryResult<TrialsQuery, TrialsQueryVariables>;
export const TrialDocument = gql`
    query trial($name: String!) {
  trial(name: $name) {
    id
    name
    organization {
      name
    }
    eproQuestionnaires {
      id
      label
      canEdit
    }
  }
}
    `;

/**
 * __useTrialQuery__
 *
 * To run a query within a React component, call `useTrialQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTrialQuery(baseOptions: Apollo.QueryHookOptions<TrialQuery, TrialQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrialQuery, TrialQueryVariables>(TrialDocument, options);
      }
export function useTrialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrialQuery, TrialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrialQuery, TrialQueryVariables>(TrialDocument, options);
        }
export type TrialQueryHookResult = ReturnType<typeof useTrialQuery>;
export type TrialLazyQueryHookResult = ReturnType<typeof useTrialLazyQuery>;
export type TrialQueryResult = Apollo.QueryResult<TrialQuery, TrialQueryVariables>;
export const QuestionnaireDocument = gql`
    query questionnaire($trial: String!, $id: ID!) {
  eproQuestionnaire(trial: $trial, id: $id) {
    id
    label
    canEdit
    eproQuestions {
      id
      seq
      label
      type
      config
    }
  }
}
    `;

/**
 * __useQuestionnaireQuery__
 *
 * To run a query within a React component, call `useQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnaireQuery({
 *   variables: {
 *      trial: // value for 'trial'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<QuestionnaireQuery, QuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(QuestionnaireDocument, options);
      }
export function useQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnaireQuery, QuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnaireQuery, QuestionnaireQueryVariables>(QuestionnaireDocument, options);
        }
export type QuestionnaireQueryHookResult = ReturnType<typeof useQuestionnaireQuery>;
export type QuestionnaireLazyQueryHookResult = ReturnType<typeof useQuestionnaireLazyQuery>;
export type QuestionnaireQueryResult = Apollo.QueryResult<QuestionnaireQuery, QuestionnaireQueryVariables>;
export const QuestionnairesDocument = gql`
    query questionnaires($trial: String!) {
  eproQuestionnaires(trial: $trial) {
    id
    label
    canEdit
  }
}
    `;

/**
 * __useQuestionnairesQuery__
 *
 * To run a query within a React component, call `useQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionnairesQuery({
 *   variables: {
 *      trial: // value for 'trial'
 *   },
 * });
 */
export function useQuestionnairesQuery(baseOptions: Apollo.QueryHookOptions<QuestionnairesQuery, QuestionnairesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionnairesQuery, QuestionnairesQueryVariables>(QuestionnairesDocument, options);
      }
export function useQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionnairesQuery, QuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionnairesQuery, QuestionnairesQueryVariables>(QuestionnairesDocument, options);
        }
export type QuestionnairesQueryHookResult = ReturnType<typeof useQuestionnairesQuery>;
export type QuestionnairesLazyQueryHookResult = ReturnType<typeof useQuestionnairesLazyQuery>;
export type QuestionnairesQueryResult = Apollo.QueryResult<QuestionnairesQuery, QuestionnairesQueryVariables>;
export const SchedulerDocument = gql`
    query scheduler($trial: String!) {
  eproReferenceDates(trial: $trial) {
    id
    label
    isYearly
  }
  eproSchedules(trial: $trial) {
    id
    canEdit
    days
    periodDays
    eproQuestionnaire {
      id
    }
    eproReferenceDate {
      id
    }
  }
  eproQuestionnaires(trial: $trial) {
    id
    label
  }
}
    `;

/**
 * __useSchedulerQuery__
 *
 * To run a query within a React component, call `useSchedulerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulerQuery({
 *   variables: {
 *      trial: // value for 'trial'
 *   },
 * });
 */
export function useSchedulerQuery(baseOptions: Apollo.QueryHookOptions<SchedulerQuery, SchedulerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchedulerQuery, SchedulerQueryVariables>(SchedulerDocument, options);
      }
export function useSchedulerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchedulerQuery, SchedulerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchedulerQuery, SchedulerQueryVariables>(SchedulerDocument, options);
        }
export type SchedulerQueryHookResult = ReturnType<typeof useSchedulerQuery>;
export type SchedulerLazyQueryHookResult = ReturnType<typeof useSchedulerLazyQuery>;
export type SchedulerQueryResult = Apollo.QueryResult<SchedulerQuery, SchedulerQueryVariables>;
export const RemindersDocument = gql`
    query reminders($trial: String!) {
  eproSchedules(trial: $trial) {
    id
    days
    periodDays
    eproQuestionnaire {
      id
      label
    }
    eproReferenceDate {
      id
      label
    }
    eproReminders {
      id
      referenceType
      days
      subject
      body
    }
  }
}
    `;

/**
 * __useRemindersQuery__
 *
 * To run a query within a React component, call `useRemindersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemindersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemindersQuery({
 *   variables: {
 *      trial: // value for 'trial'
 *   },
 * });
 */
export function useRemindersQuery(baseOptions: Apollo.QueryHookOptions<RemindersQuery, RemindersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RemindersQuery, RemindersQueryVariables>(RemindersDocument, options);
      }
export function useRemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RemindersQuery, RemindersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RemindersQuery, RemindersQueryVariables>(RemindersDocument, options);
        }
export type RemindersQueryHookResult = ReturnType<typeof useRemindersQuery>;
export type RemindersLazyQueryHookResult = ReturnType<typeof useRemindersLazyQuery>;
export type RemindersQueryResult = Apollo.QueryResult<RemindersQuery, RemindersQueryVariables>;
export const AccountDocument = gql`
    query account($clientId: String!, $pin: String!) {
  eproAccount(clientId: $clientId, pin: $pin) {
    id
    clientId
    pin
    email
    phone
  }
}
    `;

/**
 * __useAccountQuery__
 *
 * To run a query within a React component, call `useAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      pin: // value for 'pin'
 *   },
 * });
 */
export function useAccountQuery(baseOptions: Apollo.QueryHookOptions<AccountQuery, AccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
      }
export function useAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountQuery, AccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountQuery, AccountQueryVariables>(AccountDocument, options);
        }
export type AccountQueryHookResult = ReturnType<typeof useAccountQuery>;
export type AccountLazyQueryHookResult = ReturnType<typeof useAccountLazyQuery>;
export type AccountQueryResult = Apollo.QueryResult<AccountQuery, AccountQueryVariables>;
export const PatientOverviewDocument = gql`
    query patientOverview($clientId: String!, $pin: String!) {
  eproIncompleteQuestionnaires(clientId: $clientId, pin: $pin) {
    id
    begin
    end
    eproSchedule {
      eproQuestionnaire {
        label
      }
    }
  }
  eproCompletedQuestionnaires(clientId: $clientId, pin: $pin) {
    id
    updatedAt
    eproSchedule {
      eproQuestionnaire {
        label
      }
    }
  }
}
    `;

/**
 * __usePatientOverviewQuery__
 *
 * To run a query within a React component, call `usePatientOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientOverviewQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      pin: // value for 'pin'
 *   },
 * });
 */
export function usePatientOverviewQuery(baseOptions: Apollo.QueryHookOptions<PatientOverviewQuery, PatientOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PatientOverviewQuery, PatientOverviewQueryVariables>(PatientOverviewDocument, options);
      }
export function usePatientOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PatientOverviewQuery, PatientOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PatientOverviewQuery, PatientOverviewQueryVariables>(PatientOverviewDocument, options);
        }
export type PatientOverviewQueryHookResult = ReturnType<typeof usePatientOverviewQuery>;
export type PatientOverviewLazyQueryHookResult = ReturnType<typeof usePatientOverviewLazyQuery>;
export type PatientOverviewQueryResult = Apollo.QueryResult<PatientOverviewQuery, PatientOverviewQueryVariables>;
export const IncompleteQuestionnaireDocument = gql`
    query incompleteQuestionnaire($clientId: String!, $pin: String!, $id: ID!) {
  eproIncompleteQuestionnaire(clientId: $clientId, pin: $pin, id: $id) {
    begin
    end
    snapshot
    eproSchedule {
      eproQuestionnaire {
        id
        label
        eproQuestions {
          id
          seq
          label
          type
          config
        }
      }
    }
  }
}
    `;

/**
 * __useIncompleteQuestionnaireQuery__
 *
 * To run a query within a React component, call `useIncompleteQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncompleteQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncompleteQuestionnaireQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      pin: // value for 'pin'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncompleteQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<IncompleteQuestionnaireQuery, IncompleteQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncompleteQuestionnaireQuery, IncompleteQuestionnaireQueryVariables>(IncompleteQuestionnaireDocument, options);
      }
export function useIncompleteQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncompleteQuestionnaireQuery, IncompleteQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncompleteQuestionnaireQuery, IncompleteQuestionnaireQueryVariables>(IncompleteQuestionnaireDocument, options);
        }
export type IncompleteQuestionnaireQueryHookResult = ReturnType<typeof useIncompleteQuestionnaireQuery>;
export type IncompleteQuestionnaireLazyQueryHookResult = ReturnType<typeof useIncompleteQuestionnaireLazyQuery>;
export type IncompleteQuestionnaireQueryResult = Apollo.QueryResult<IncompleteQuestionnaireQuery, IncompleteQuestionnaireQueryVariables>;
export const CompletedQuestionnaireDocument = gql`
    query completedQuestionnaire($clientId: String!, $pin: String!, $id: ID!) {
  eproCompletedQuestionnaire(clientId: $clientId, pin: $pin, id: $id) {
    eproSchedule {
      eproQuestionnaire {
        id
        label
        eproQuestions {
          id
          seq
          label
          type
          config
        }
      }
    }
    eproAnswers {
      id
      value
      eproQuestion {
        id
        type
      }
    }
  }
}
    `;

/**
 * __useCompletedQuestionnaireQuery__
 *
 * To run a query within a React component, call `useCompletedQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompletedQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompletedQuestionnaireQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      pin: // value for 'pin'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompletedQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<CompletedQuestionnaireQuery, CompletedQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompletedQuestionnaireQuery, CompletedQuestionnaireQueryVariables>(CompletedQuestionnaireDocument, options);
      }
export function useCompletedQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompletedQuestionnaireQuery, CompletedQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompletedQuestionnaireQuery, CompletedQuestionnaireQueryVariables>(CompletedQuestionnaireDocument, options);
        }
export type CompletedQuestionnaireQueryHookResult = ReturnType<typeof useCompletedQuestionnaireQuery>;
export type CompletedQuestionnaireLazyQueryHookResult = ReturnType<typeof useCompletedQuestionnaireLazyQuery>;
export type CompletedQuestionnaireQueryResult = Apollo.QueryResult<CompletedQuestionnaireQuery, CompletedQuestionnaireQueryVariables>;