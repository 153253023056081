import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Typography
} from "@mui/material";

export function NotFound() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="md">
      <Box sx={{ marginTop: 8 }}>
        <Typography variant="h1" component="div">
          {"404"}
        </Typography>
      </Box>
      <Box sx={{ marginY: 2 }}>
        <Typography variant="h2" component="div">
          {"Page not found."}
        </Typography>
      </Box>
      <Button onClick={() => navigate(-1)}>{"GO BACK"}</Button>
    </Container>
  );
}
