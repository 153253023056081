import { Questionnaire, ReferenceDate, Schedule as GS } from "./graphql";

export type Schedule = {
  id: string | null;
  eproQuestionnaireId: string;
  eproReferenceDateId: string;
  days: number;
  periodDays?: number;
  canEdit: boolean;
}

type GSType = (
  { __typename?: 'Schedule' }
  & Pick<GS, 'id' | 'days' | 'periodDays' | 'canEdit'>
  & { eproQuestionnaire: (
    { __typename?: 'Questionnaire' }
    & Pick<Questionnaire, 'id'>
  ), eproReferenceDate: (
    { __typename?: 'ReferenceDate' }
    & Pick<ReferenceDate, 'id'>
  ) }
);

export function from(value: GSType): Schedule {
  const v = {
    id: value.id,
    eproQuestionnaireId: value.eproQuestionnaire.id,
    eproReferenceDateId: value.eproReferenceDate.id,
    days: value.days,
    canEdit: value.canEdit
  };
  if (value.periodDays) {
    return {...v, periodDays: value.periodDays}
  }
  return v;
}

export function toParams(value: Schedule): Omit<Schedule, "canEdit"> {
  return {
    id: value.id,
    eproQuestionnaireId: value.eproQuestionnaireId,
    eproReferenceDateId: value.eproReferenceDateId,
    days: value.days,
    periodDays: value.periodDays
  };
}
