import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  Toolbar,
  Container,
} from "@mui/material";
import { DesignerTheme } from '../components/Theme';
import { DesignerAppBar } from "../components/DesignerAppBar";
import { DesignerMenuDrawer } from "../components/DesignerMenuDrawer";

export const DesignerLayout = () => {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => setOpen(!open);
  return (
    <DesignerTheme>
      <DesignerAppBar open={open} toggleDrawer={toggleDrawer} />
      <DesignerMenuDrawer open={open} toggleDrawer={toggleDrawer} />
      <Box component="main" sx={{ flexGrow: 1, height: "auto", overflow: 'auto' }}>
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          <Outlet />
        </Container>
      </Box>
    </DesignerTheme>
  );
}
