import React, { Dispatch } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Box, IconButton, Grid, TextField, Radio, Checkbox } from "@mui/material";
import { DragIndicator, Close, Add } from "@mui/icons-material";
import { QuestionsAction } from '../EditableQuestionnaire';
import * as Seq from "../../types/seq";
import {
  CheckBox,
  Radio as RadioType,
  isCheckBox,
  SelectType
} from '../../types/question';

type Props = { id: string, question: CheckBox | RadioType, dispatch: Dispatch<QuestionsAction> };

function SelectList({ id, question, dispatch }: Props) {
  const onClose = (st: SelectType) => () => {
    const values = question.values.filter(v => v.seq !== st.seq).map((v, seq) => ({...v, seq}));
    dispatch({ type: "update", id, value: {...question, values: values} });
  };

  const onLabelChange = (st: SelectType, label: string) => {
    const value = {...question, values: question.values.map(v => v.seq === st.seq ? {...st, label} : v)};
    dispatch({ type: "update", id, value });
  };

  return (
    <>
      {question.values.map((v, i) => (
        <Draggable draggableId={`${i}`} index={i} key={i}>
          {provided => (
            <Grid
              key={i}
              container
              wrap="nowrap"
              direction="row"
              alignItems="center"
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <Grid item {...provided.dragHandleProps}>
                <DragIndicator fontSize="small" sx={{ opacity: 0.3, cursor: "move", display: "block" }} />
              </Grid>
              <Grid item>
                { isCheckBox(question) ? <Checkbox disabled /> : <Radio disabled /> }
              </Grid>
              <Grid item xs={12} sm={10}>
                <TextField
                  fullWidth
                  value={v.label}
                  placeholder="Option"
                  size="small"
                  onChange={e => onLabelChange(v, e.target.value)}
                />
              </Grid>
              <Grid item>
                {v.seq !== 0 && (
                  <IconButton aria-label="close" onClick={onClose(v)} size="large">
                    <Close />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          )}
        </Draggable>
      ))}
    </>
  );
}

export function DraggableSelectList({ id, question, dispatch }: Props) {
  const onAdd = () => {
    const last = question.values[question.values.length - 1]?.seq;
    const value = { label: "", seq: last !== undefined ? last + 1 : 0 };
    dispatch({ type: "update", id, value: {...question, values: [...question.values, value]} });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }
    const values = Seq.swap(question.values, result.source.index, result.destination.index).map((v, seq) => ({...v, seq }));
    dispatch({ type: "update", id, value: {...question, values }});
  };

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {provided => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              <SelectList id={id} question={question} dispatch={dispatch} />
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <IconButton aria-label="add" onClick={onAdd} size="large">
        <Add />
      </IconButton>
    </Box>
  );
}