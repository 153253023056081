export type Patient = {
  id: string;
  clientId: string;
  pin: string;
  email: string;
  phone: string;
}

export type AuthToken = {
  uid: string;
  client: string;
  token: string;
}

type Role = "admin" | "dm" | "user" | "monitor";

export type DesignerUser = {
  id?: number;
  firstName?: string;
  lastName?: string;
  firstNameEn?: string;
  lastNameEn?: string;
  email: string;
  role: Role;
  uuid?: string;
  authToken?: AuthToken;
}

export type User = Patient | DesignerUser

export function parse(value: string): User {
  const v = JSON.parse(value);
  if (v.pin) {
    return v as Patient;
  }
  return v as DesignerUser;
}

export function isLoggedIn(user: User | null) {
  return user ? true : false;
}

export function isPatient(user: User | null): user is Patient {
  return !!user && (user as Patient).pin !== undefined;
}

export function isDesignerUser(user: User | null): user is DesignerUser {
  return !!user && (user as DesignerUser).authToken !== undefined;
}

export function isLoggedInPatient(user: User | null): user is Patient {
  return isLoggedIn(user) && isPatient(user)
}

export function isLoggedInDesignerUser(user: User | null): user is DesignerUser {
  return isLoggedIn(user) && isDesignerUser(user)
}