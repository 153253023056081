import { Question, match as matchQuestion } from "./question";
import { QuestionKind, Answer as GA, Question as GQ, CreateCompletedQuestionnaireInput } from "./graphql";

type AnswerProps = {
  id: number | null;
  questionId: number;
  type: QuestionKind;
}

export type CheckBoxAnswer = AnswerProps & {
  type: QuestionKind.Check;
  value: number[];
}

export type RadioAnswer = AnswerProps & {
  type: QuestionKind.Radio;
  value: number | null;
}

export type SliderAnswer = AnswerProps & {
  type: QuestionKind.Slider;
  value: number;
}

export type TextAnswer = AnswerProps & {
  type: QuestionKind.Text;
  value: string;
}

export type Answer =
  | CheckBoxAnswer
  | RadioAnswer
  | SliderAnswer
  | TextAnswer;

export function init(question: Question): Answer {
  return matchQuestion<Answer>(
    question,
    q => ({ id: null, questionId: q.id!, type: q.type, value: "" }),
    q => ({ id: null, questionId: q.id!, type: q.type, value: null }),
    q => ({ id: null, questionId: q.id!, type: q.type, value: [] }),
    q => ({ id: null, questionId: q.id!, type: q.type, value: q.min })
  );
}

export function isCheckBox(answer: Answer): answer is CheckBoxAnswer {
  return answer.type === QuestionKind.Check;
}

export function isRadio(answer: Answer): answer is RadioAnswer {
  return answer.type === QuestionKind.Radio;
}

export function isSlider(answer: Answer): answer is SliderAnswer {
  return answer.type === QuestionKind.Slider;
}

export function isText(answer: Answer): answer is TextAnswer {
  return answer.type === QuestionKind.Text;
}

export function match<T>(
  answer: Answer,
  f1: (text: TextAnswer) => T,
  f2: (radio: RadioAnswer) => T,
  f3: (checkBox: CheckBoxAnswer) => T,
  f4: (slider: SliderAnswer) => T
) {
  if (isText(answer)) {
    return f1(answer);
  }
  if (isRadio(answer)) {
    return f2(answer);
  }
  if (isCheckBox(answer)) {
    return f3(answer);
  }
  if (isSlider(answer)) {
    return f4(answer);
  }
  throw new Error(`Invalid Argument: ${answer}`);
}

type FromAnswer = (
  { __typename?: 'Answer' }
  & Pick<GA, 'id' | 'value'>
  & { eproQuestion: ({ __typename?: 'Question' } & Pick<GQ, 'id' | 'type'>) }
);

export function from(answer: FromAnswer): Answer {
  return {
    id: Number(answer.id),
    questionId: Number(answer.eproQuestion.id),
    type: answer.eproQuestion.type,
    value: answer.value.value
  };
}

export function fromSnapshot(question: Question, value: any): Answer {
  return matchQuestion<Answer>(
    question,
    q => ({ id: null, questionId: q.id!, type: q.type, value: value }),
    q => ({ id: null, questionId: q.id!, type: q.type, value: value }),
    q => ({ id: null, questionId: q.id!, type: q.type, value: value }),
    q => ({ id: null, questionId: q.id!, type: q.type, value: value })
  );
}

export function toParams(answer: Answer): CreateCompletedQuestionnaireInput {
  const value = match<{ value: any }>(
    answer,
    t => ({ value: t.value }),
    r => ({ value: r.value }),
    c => ({ value: c.value }),
    s => ({ value: s.value })
  );
  return { value, eproQuestionId: answer.questionId.toString() };
}
