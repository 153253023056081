import {
  Question as GQ,
  QuestionKind,
  QuestionInput
} from "../types/graphql";

type QuestionProps = {
  id: number | null,
  label: string,
  seq: number,
  type: QuestionKind
}

export type SelectType = {
  seq: number;
  label: string;
}

export type CheckBox = QuestionProps & {
  type: QuestionKind.Check,
  values: SelectType[]
}

export type Radio = QuestionProps & {
  type: QuestionKind.Radio,
  values: SelectType[]
}

export type Slider = QuestionProps & {
  type: QuestionKind.Slider,
  step: number,
  min: number,
  max: number,
  minLabel: string,
  maxLabel: string
}

export type Text = QuestionProps & {
  type: QuestionKind.Text,
}

export type Question = CheckBox | Radio | Slider | Text;

export function isQuestionKind(v: string): v is QuestionKind {
  return v === QuestionKind.Text
    || v === QuestionKind.Radio
    || v === QuestionKind.Check
    || v === QuestionKind.Slider;
}

export function isCheckBox(question: Question): question is CheckBox {
  return question.type === QuestionKind.Check;
}

export function isRadio(question: Question): question is Radio {
  return question.type === QuestionKind.Radio;
}

export function isSlider(question: Question): question is Slider {
  return question.type === QuestionKind.Slider;
}

export function isText(question: Question): question is Text {
  return question.type === QuestionKind.Text;
}

export function match<T>(
  question: Question,
  f1: (text: Text) => T,
  f2: (radio: Radio) => T,
  f3: (checkBox: CheckBox) => T,
  f4: (slider: Slider) => T
) {
  if (isText(question)) {
    return f1(question);
  }
  if (isRadio(question)) {
    return f2(question);
  }
  if (isCheckBox(question)) {
    return f3(question);
  }
  if (isSlider(question)) {
    return f4(question);
  }
  throw new Error(`Invalid Argument: ${question}`);
}

export function replaceWith(from: Question, target: QuestionKind): Question {
  const replaceCheckOrRadio = (q: CheckBox | Radio) => {
    if (target === QuestionKind.Check || target === QuestionKind.Radio) {
      return {...q, type: target, values: q.values};
    }
    if (target === QuestionKind.Slider) {
        return {...q, type: target, step: 1, min: 0, minLabel: "", max: 100, maxLabel: "" }
    }
    const {values, ...result} = q;
    return {...result, type: target};
  };

  return match<Question>(
    from,
    text => {
      if (target === QuestionKind.Check || target === QuestionKind.Radio) {
        return {...text, type: target, values: [{ seq: 0, label: "" }]};
      }
      if (target === QuestionKind.Slider) {
        return {...text, type: target, step: 1, min: 0, minLabel: "", max: 100, maxLabel: "" }
      }
      return {...text, type: target};
    },
    replaceCheckOrRadio,
    replaceCheckOrRadio,
    slider => {
      if (target === QuestionKind.Check || target === QuestionKind.Radio) {
        return {...slider, type: target, values: [{ seq: 0, label: "" }]};
      }
      return {...slider, type: target};
    }
  );
}

export function from(value: Pick<GQ, 'id' | 'seq' | 'label' | 'type' | 'config'>): Question {
  const result = {
    id: Number(value.id),
    seq: value.seq,
    label: value.label,
  };
  if (value.type === QuestionKind.Check || value.type === QuestionKind.Radio) {
    return {...result, type: value.type, values: value.config.values };
  }
  if (value.type === QuestionKind.Slider) {
    return {
      ...result,
      type: value.type,
      step: value.config.step,
      min: value.config.min,
      max: value.config.max,
      minLabel: value.config.minLabel,
      maxLabel: value.config.maxLabel
    };
  }
  return {...result, type: value.type};
}

export function toParams(question: Question): QuestionInput {
  const {id, seq, label, type, ...config} = question;
  if (question.type === QuestionKind.Check || question.type === QuestionKind.Radio) {
    return { id: id ? id.toString() : "", seq, label, type, config: { values: question.values } };
  }
  return { id: id ? id.toString() : "", seq, label, type, config };
}