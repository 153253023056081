import React, { Dispatch, SetStateAction, useState, useContext } from "react";
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  RadioGroup,
  Radio,
  InputLabel,
  IconButton,
  Typography,
  Tooltip,
  FormHelperText,
  Chip,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Fab,
  FormControl,
  FormControlLabel,
  Stack,
} from "@mui/material";
import * as Seq from "../types/seq";
import * as Store from "../api/cache";
import { Schedule, from as fromSchedule, toParams } from "../types/schedule";
import { ReferenceDate, from as fromReferenceDate } from "../types/reference_date";
import {
  Edit,
  Done,
  Cancel,
  AddCircleOutline,
  SaveAlt,
  Add
} from "@mui/icons-material";
import {
  useUpdateSchedulesMutation,
  useSchedulerQuery,
} from "../types/graphql";
import { ToastContext } from "../contexts/ToastContext";

type QuestionnaireType = { id: string, label: string };
type QuestionnairesType = QuestionnaireType[];
type PeriodType = "indefinite" | "days";

const ScheduleDialog = ({
  open,
  setOpen,
  onSubmit,
  questionnaires
}: {
  open: boolean,
  questionnaires: QuestionnairesType
  setOpen: Dispatch<SetStateAction<boolean>>,
  onSubmit: (questionnaire: QuestionnaireType, periodDays: number | undefined) => void,
}) => {
  const [questionnaireId, setQuestionnaireId] = useState<string>("");
  const [periodType, setPeriodType] = useState<PeriodType>("indefinite");
  const [periodDays, setPeriodDays] = useState<number>(30);
  const isDaysMode = periodType === 'days';
  const validateQuestionnaire = !!questionnaireId;
  const validatePeriod = periodType === "indefinite" || (isDaysMode && !!periodDays);
  const onClose = (_: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const onAdd = () => {
    setOpen(false);
    setQuestionnaireId("");
    onSubmit(questionnaires.find(q => q.id === questionnaireId)!, isDaysMode ? periodDays : undefined);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Schedule</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <FormControl error={!validateQuestionnaire}>
            <InputLabel id="questionnaire-label">Questionnaire</InputLabel>
            <Select
              autoWidth
              labelId="questionnaire-label"
              value={questionnaireId}
              onChange={e => setQuestionnaireId(e.target.value)}
            >
              <MenuItem value="">&nbsp;</MenuItem>
              {questionnaires.map(q => (
                <MenuItem key={q.id} value={q.id}>{q.label}</MenuItem>
              ))}
            </Select>
            {!validateQuestionnaire && <FormHelperText>Required.</FormHelperText>}
          </FormControl>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="Period"
              defaultValue="indefinite"
              value={periodType}
              onChange={e => setPeriodType(e.target.value as PeriodType)}
            >
              <FormControlLabel label="Indefinite period" value="indefinite" control={<Radio />} />
              <Box flexDirection="row">
                <FormControlLabel label="Days" value="days" control={<Radio />} />
                <TextField
                  size="small"
                  type="number"
                  disabled={!isDaysMode}
                  inputProps={{ min: "1", step: "10", inputMode: "numeric" }}
                  value={periodDays}
                  error={!validatePeriod}
                  helperText={!validatePeriod && "Required."}
                  onChange={e => setPeriodDays(Number(e.target.value))}
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!validatePeriod || !validateQuestionnaire} onClick={onAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}

const EditableRow = ({ referenceDate, days, questionnaires, schedules, setSchedules }: {
  referenceDate: ReferenceDate,
  days: number[],
  questionnaires: QuestionnairesType,
  schedules: Schedule[],
  setSchedules: (referenceDateId: string, schedules: Schedule[]) => void
}) => {
  const [edit, setEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [targetDays, setTargetDays] = useState(0);
  const [values, setValues] = useState(schedules);

  const filterScheduleAndQuestionnaires = (days: number): [Schedule[], QuestionnairesType] => {
    const vs = values.filter(s => s.days === days);
    const qs = questionnaires.filter(q => !vs.some(v => v.eproQuestionnaireId === q.id));
    return [vs, qs];
  };

  const onDelete = (schedule: Schedule) => () =>
    setValues(values.filter(v => !(v.days === schedule.days && v.eproQuestionnaireId === schedule.eproQuestionnaireId)));

  const onSubmit = (questionnaire: QuestionnaireType, periodDays: number | undefined) => {
    const v = {
      id: null,
      eproReferenceDateId: referenceDate.id,
      eproQuestionnaireId: questionnaire.id,
      days: targetDays,
      canEdit: true,
      periodDays
    };
    setValues([...values, v]);
  };

  return (
    <TableRow>
      <TableCell align="center">
        {referenceDate.label}
      </TableCell>
      {days.map(d => {
        const [vs, qs] = filterScheduleAndQuestionnaires(d);
        return (
          <TableCell key={d}>
            {vs.map(s => {
              const t = questionnaires.find(q => q.id === s.eproQuestionnaireId)!.label;
              return (
                <Box key={s.eproQuestionnaireId} sx={{ marginY: 1 }}>
                  <Tooltip title={`${t}(period days: ${ s.periodDays || 'indefinite'})`}>
                    {edit && s.canEdit ? (
                      <Chip variant="outlined" label={t} onDelete={onDelete(s)} sx={theme => ({ width: theme.typography.pxToRem(140) })} />
                    ) : (
                      <Typography noWrap sx={theme => ({ width: theme.typography.pxToRem(140) })}>{t}</Typography>
                    )}
                  </Tooltip>
                </Box>
            )})}
            {edit && Seq.any(qs) && (
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={() => { setOpenDialog(true); setTargetDays(d); }}
              >
                Add
              </Button>
            )}
          </TableCell>
      )})}
      <TableCell align="center">
        {edit && (
          <>
            <Box>
              <IconButton
                onClick={() => { setSchedules(referenceDate.id, values); setEdit(false) }}
                size="large">
                <Done color="primary" />
              </IconButton>
            </Box>
            <Box>
              <IconButton onClick={() => { setValues(schedules); setEdit(false); }} size="large">
                <Cancel />
              </IconButton>
            </Box>
          </>
        )}
        {!edit && Seq.any(days) && (
          <IconButton onClick={() => setEdit(true)} size="large">
            <Edit />
          </IconButton>
        )}
      </TableCell>
      <ScheduleDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onSubmit={onSubmit}
        questionnaires={filterScheduleAndQuestionnaires(targetDays)[1]}
      />
    </TableRow>
  );
};

const DaysDialog = ({ open, setOpen, onSubmit, days }: {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  onSubmit: (days: number) => void,
  days: number[]
}) => {
  const [value, setValue] = useState("");
  const validate = days.every(d => d !== Number(value));

  const onClose = (_: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Days</DialogTitle>
      <DialogContent>
        <TextField
          type="number"
          size="small"
          inputProps={{ min: "1", step: "10", inputMode: "numeric" }}
          value={value}
          onChange={e => setValue(e.target.value)}
          error={!validate}
          helperText={!validate && "Already registered."}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button disabled={!validate} onClick={() => { onSubmit(Number(value)); setOpen(false) }}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}

const ReferenceDateDialog = ({ open, setOpen, onSubmit, referenceDates }: {
  open: boolean,
  setOpen: Dispatch<SetStateAction<boolean>>,
  onSubmit: (rdf: ReferenceDate) => void,
  referenceDates: ReferenceDate[]
}) => {
  const [value, setValue] = useState<string | undefined>(referenceDates[0]?.id);
  const onClose = (_: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const onAdd = () => {
    setOpen(false);
    setValue(referenceDates.find(r => r.id !== value)?.id);
    onSubmit(referenceDates.find(r => r.id === value)!);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Reference Date</DialogTitle>
      <DialogContent>
        <Select
          autoWidth
          displayEmpty
          value={value}
          onChange={e => setValue(e.target.value)}
        >
          {referenceDates.map(r => <MenuItem key={r.id} value={r.id}>{r.label}</MenuItem>)}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onAdd}>Add</Button>
      </DialogActions>
    </Dialog>
  )
}

export function Scheduler() {
  const { popUpMessage } = useContext(ToastContext);
  const { trial } = useParams<{ trial: string }>();
  const [questionnaires, setQuestionnaires] = useState<{ id: string, label: string}[]>([]);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [referenceDates, setReferenceDates] = useState<ReferenceDate[]>([]);
  const [days, setDays] = useState<number[]>([]);
  const [referenceIds, setReferenceIds] = useState<string[]>([]);
  const [openDays, setOpenDays] = useState(false);
  const [openReferenceDate, setOpenReferenceDate] = useState(false);

  useSchedulerQuery({
    variables: { trial: trial! },
    onCompleted: d => {
      setQuestionnaires(d.eproQuestionnaires.map(q => ({ id: q.id, label: q.label })));
      setSchedules(d.eproSchedules.map(fromSchedule));
      setDays(Seq.uniqBy(d.eproSchedules, s => s.days));
      setReferenceIds(Seq.uniqBy(d.eproSchedules, s => s.eproReferenceDate.id));
      setReferenceDates(d.eproReferenceDates.map(fromReferenceDate));
    }
  });

  const [updateSchedules] = useUpdateSchedulesMutation({
    onCompleted: data => {
      const ss = data.updateEproSchedules.schedules.map(s => fromSchedule(s));
      setSchedules(ss);
      setDays(Seq.uniqBy(ss, s => s.days).sort((l, r) => l - r));
      setReferenceIds(Seq.uniqBy(ss, s => s.eproReferenceDateId));
      popUpMessage("success", "Saved successfully.");
    },
    onError: _ => popUpMessage("error", "Save failed."),
    update: (cache, { data }) => {
      const variables = { trial: trial! };
      const cached = Store.readQuestionnaires(cache, variables);
      if (cached) {
        const updating = cached.eproQuestionnaires.map(q => {
          if (data?.updateEproSchedules.schedules.some(s => s.eproQuestionnaire.id === q.id)) {
            return { ...q, canEdit: false };
          }
          return { ...q, canEdit: true };
        });
        Store.writeQuestionnaires(cache, variables, { eproQuestionnaires: updating });
      }

      const schedulerCache = Store.readScheduler(cache, variables);
      if (schedulerCache) {
        Store.writeScheduler(cache, variables, {...schedulerCache, eproSchedules: data?.updateEproSchedules.schedules.map(x => x) || []});
      }
    }
  });

  const setScs = (referenceDateId: string, values: Schedule[]) => {
    const others = schedules.filter(s => s.eproReferenceDateId !== referenceDateId);
    setSchedules([...others, ...values]);
  };

  const onSave = () => {
    updateSchedules({ variables: { trial: trial!, input: schedules.map(toParams) }});
  };

  return (
    <TableContainer component={Paper} sx={{ padding: 4 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={1} />
            <TableCell align="center" colSpan={days.length}>
              Days
            </TableCell>
            <TableCell align="center" colSpan={1} />
          </TableRow>
          <TableRow>
            <TableCell align="center">Reference Date</TableCell>
            {days.map(d => (
              <TableCell key={d} align="center">
                <Typography>{d}</Typography>
              </TableCell>
            ))}
            <TableCell align="center">
              <IconButton onClick={() => setOpenDays(true)} size="large">
                <AddCircleOutline />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {referenceIds.map(rid => (
            <EditableRow
              key={rid}
              referenceDate={referenceDates.find(r => r.id === rid)!}
              days={days}
              questionnaires={questionnaires}
              schedules={schedules.filter(s => s.eproReferenceDateId === rid)}
              setSchedules={setScs}
            />))}
          {referenceIds.length !== referenceDates.length && (
            <>
              <TableRow>
                <TableCell align="center">
                  <IconButton onClick={() => setOpenReferenceDate(true)} size="large">
                    <AddCircleOutline />
                  </IconButton>
                </TableCell>
                <TableCell colSpan={days.length + 1} />
              </TableRow>
              <ReferenceDateDialog
                open={openReferenceDate}
                setOpen={setOpenReferenceDate}
                referenceDates={referenceDates.filter(r => referenceIds.every(id => id !== r.id))}
                onSubmit={r => setReferenceIds([...referenceIds, r.id])}
              />
            </>
          )}
        </TableBody>
      </Table>
      <DaysDialog
        open={openDays}
        setOpen={setOpenDays}
        days={days}
        onSubmit={d => setDays([...days, d].sort((l, r) => l - r))}
      />

      <Tooltip title="Save" aria-label="save" placement="top">
        <Fab
          color="primary"
          aria-label="save"
          sx={{
            position: "fixed",
            top: "auto",
            left: "auto",
            bottom: 10,
            right: 8
          }}
          onClick={onSave}
        >
          <SaveAlt />
        </Fab>
      </Tooltip>
    </TableContainer>
  );
}
