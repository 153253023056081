import * as React from 'react';
import { Snackbar, SnackbarCloseReason } from '@mui/material';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';

const XAlert = React.forwardRef<any, AlertProps>((props: AlertProps, ref) =>
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
);

type Props = {
  open: boolean;
  severity: AlertColor;
  message: string;
  onClose: () => void;
};

export function AlertSnackbar({ open, severity, message, onClose }: Props) {

  const handleClose = (_?: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <XAlert onClose={handleClose} severity={severity}>
        {message}
      </XAlert>
    </Snackbar>
  )
}