import React, { useState, useContext, useEffect } from "react";
import { format } from "date-fns";
import { Flatten, PropType } from "../types/core";
import * as Store from "../api/cache";
import {
  Box,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  RadioGroup,
  Radio,
  IconButton,
  Typography,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormControlLabel,
  Stack,
  Collapse,
} from "@mui/material";
import { TimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { KeyboardArrowUp, KeyboardArrowDown, Add, Edit, DeleteForever } from "@mui/icons-material";
import {
  useRemindersQuery,
  useUpsertReminderMutation,
  useDeleteReminderMutation,
  RemindersQuery,
  Reference
} from "../types/graphql";
import { DesignerError } from "../components/Error";
import { ToastContext } from "../contexts/ToastContext";
import { useParams } from "react-router-dom";

type ScheduleType = Flatten<PropType<RemindersQuery, "eproSchedules">>;
type ReminderType = Flatten<PropType<ScheduleType, "eproReminders">>;

function EditDialog({ open, setOpen, trial, schedule, reminder }: {
  open: boolean,
  setOpen: (open: boolean) => void,
  trial: string,
  schedule: ScheduleType,
  reminder: undefined | ReminderType
}) {
  const { popUpMessage } = useContext(ToastContext);
  const [days, setDays] = useState(reminder?.days || 0);
  const [referenceType, setReferenceType] = useState(reminder?.referenceType || Reference.Begin);
  // const [sendingTime, setSendingTime] = useState<Date | null>(reminder?.sendingTime ?? null);
  const [subject, setSubject] = useState(reminder?.subject || "");
  const [body, setBody] = useState(reminder?.body || "");
  const validate = !!subject && !!body; // && !!sendingTime;

  useEffect(() => {
    setDays(reminder?.days || 0);
    setReferenceType(reminder?.referenceType ?? Reference.Begin);
    // setSendingTime(reminder?.sendingTime ?? null);
    setSubject(reminder?.subject ?? "");
    setBody(reminder?.body ?? '');
  }, [reminder]);

  const [upsertReminder] = useUpsertReminderMutation({
    onCompleted: _ => {
      popUpMessage("success", "Saved successfully.");
      setOpen(false);
    },
    onError: _ => popUpMessage("error", "Save failed."),
    update: (cache, { data }) => {
      if (!data) {
        return;
      }
      const cached = Store.readReminders(cache, { trial });
      if (!cached) {
        return;
      }
      const eproSchedules = cached.eproSchedules.map(s => {
        if (s.id !== schedule.id) {
          return s;
        }
        if (reminder) {
          return {
            ...s,
            eproReminders: s.eproReminders.map(r => r.id === reminder.id ? data.upsertEproReminder.reminder : r)
          };
        }
        return {...s, eproReminders: [...s.eproReminders, data.upsertEproReminder.reminder]};
      });
      Store.writeReminders(cache, { trial }, {...cached, eproSchedules });
    }
  });

  const onSave = () => {
    upsertReminder({ variables: {
      trial,
      input: {
        id: reminder?.id,
        eproScheduleId: schedule.id,
        days,
        referenceType,
        // sendingTime,
        subject,
        body
      }
    }})
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{`${reminder ? "Edit" : "New"} Scheduler`}</DialogTitle>
      <DialogContent>
        <Stack spacing={2} alignItems="flex-start">
          <FormControl fullWidth component="fieldset">
            <FormLabel component="legend">Reference Date Type</FormLabel>
            <RadioGroup
              row
              aria-label="ReferenceType"
              value={referenceType}
              onChange={e => setReferenceType(e.target.value === "begin" ? Reference.Begin : Reference.End)}
            >
              <FormControlLabel label="Begin Date" value="begin" control={<Radio />} />
              <FormControlLabel disabled={!schedule.periodDays} label="End Date" value="end" control={<Radio />} />
            </RadioGroup>
          </FormControl>
          <TextField
            label="Addition or subtraction days"
            size="small"
            type="number"
            inputProps={{ step: "10", inputMode: "numeric" }}
            value={days}
            onChange={e => setDays(Number(e.target.value))}
          />
          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              ampm={false}
              label="Sending time (JST)"
              value={sendingTime}
              onChange={value => setSendingTime(value)}
              renderInput={params => <TextField required size="small" {...params} />}
            />
          </LocalizationProvider> */}
          <TextField fullWidth required label="Subject" value={subject} onChange={e => setSubject(e.target.value)} />
          <TextField fullWidth required multiline rows={12} label="Body" value={body} onChange={e => setBody(e.target.value)} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} autoFocus>CANCEL</Button>
        <Button disabled={!validate} onClick={onSave} color="primary">SAVE</Button>
      </DialogActions>
    </Dialog>
  );
}

const DeleteDialog = ({ trial, open, setOpen, schedule, reminder }: {
  trial: string,
  open: boolean,
  setOpen: (_: boolean) => void,
  schedule: ScheduleType,
  reminder: undefined | ReminderType
}) => {
  const { popUpMessage } = useContext(ToastContext);
  const [deleteReminder] = useDeleteReminderMutation({
    onCompleted: _ => {
      popUpMessage("success", "Deleted successfully.");
      setOpen(false);
    },
    onError: _ => popUpMessage("error", "Delete failed."),
    update: (cache, { data }) => {
      if (!data) {
        return;
      }
      const cached = Store.readReminders(cache, { trial });
      if (!cached) {
        return;
      }
      const eproSchedules = cached.eproSchedules.map(s => {
        if (s.id === schedule.id) {
          return {...s, eproReminders: s.eproReminders.filter(r => r.id !== data.deleteEproReminder.id)};
        }
        return s;
      });
      Store.writeReminders(cache, { trial }, {...cached, eproSchedules });
    }
  });

  if (!reminder) {
    return <></>;
  }

  const onDelete = () => {
    deleteReminder({ variables: { id: reminder.id }});
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{"Are you sure you want to delete it?"}</DialogTitle>
      <DialogActions>
        <Button onClick={() => setOpen(false)} autoFocus>CANCEL</Button>
        <Button onClick={onDelete} color="secondary">DELETE</Button>
      </DialogActions>
    </Dialog>
  );
}

function Row({ trial, schedule }: { trial: string, schedule: ScheduleType }) {
  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ReminderType | undefined>(undefined);
  const openDialog = (r: ReminderType | undefined) => () => { setSelectedItem(r); setOpenEditDialog(true); }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{schedule.eproQuestionnaire.label}</TableCell>
        <TableCell>{schedule.eproReferenceDate.label}</TableCell>
        <TableCell align="right">{schedule.days}</TableCell>
        <TableCell align="right">{schedule.periodDays || "indefinite"}</TableCell>
        <TableCell align="center">
          <IconButton size="small" onClick={openDialog(undefined)}>
            <Add />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingY: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom component="div">Reminders</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Reference Date Type</TableCell>
                    <TableCell align="right">Days</TableCell>
                    {/* <TableCell align="right">Sending Time</TableCell> */}
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedule.eproReminders.map(r => (
                    <TableRow key={r.id}>
                      <TableCell>{r.referenceType === "begin" ? "Begin Date" : "End Date"}</TableCell>
                      <TableCell align="right">{r.days}</TableCell>
                      {/* <TableCell align="right">{format(r.sendingTime, "HH:mm")}</TableCell> */}
                      <TableCell align="center">
                        <IconButton size="small" onClick={() => { setSelectedItem(r); setOpenEditDialog(true) }}>
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton size="small" onClick={() => { setSelectedItem(r); setOpenDeleteDialog(true) }}>
                          <DeleteForever color="error" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <EditDialog
        open={openEditDialog}
        setOpen={o => setOpenEditDialog(o)}
        trial={trial}
        schedule={schedule}
        reminder={selectedItem}
      />
      <DeleteDialog
        open={openDeleteDialog}
        setOpen={o => setOpenDeleteDialog(o)}
        trial={trial}
        schedule={schedule}
        reminder={selectedItem}
      />
    </>
  );
}

export function Reminder() {
  const { trial } = useParams<{ trial: string }>();
  const { data, loading, error } = useRemindersQuery({ variables: { trial: trial! }});

  if (error) {
    return <DesignerError error={error} />;
  }

  if (loading || !data) {
    return <></>;
  }

  return (
    <TableContainer component={Paper} sx={{ padding: 4 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Questionnaire Name</TableCell>
            <TableCell>Reference Date</TableCell>
            <TableCell align="right">Days</TableCell>
            <TableCell align="right">Period</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.eproSchedules.map(s => (<Row key={s.id} trial={trial!} schedule={s} />))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
