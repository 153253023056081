import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ApolloError, ServerError, useApolloClient } from "@apollo/client";
import { AuthContext } from "../contexts/AuthContext";
import { Container, Box, Grid, Typography, Button } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";

type Props = {
  error: ApolloError
};

export function DesignerError({ error }: Props) {
  const navigate = useNavigate();
  const client = useApolloClient();
  const { setUser, domain } = useContext(AuthContext);
  const ne = error.networkError ? error.networkError as ServerError : error.networkError;

  if (ne && (ne.statusCode === 401 || ne.statusCode === 403)) {
    setUser(null);
    client.clearStore();
    navigate(`/${domain}/designer/login`);
  }

  if (hasNotFoundError(error)) {
    navigate(`/${domain}/404`);
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ marginTop: 8 }}>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <ErrorOutline sx={{ fontSize: 80 }} />
          </Grid>
          <Grid item md={10}>
            <Typography variant="h2" component="div">
              {"This page isn't working"}
            </Typography>
            {ne && (
              <Typography variant="h1" component="div">
                {`HTTP ERROR ${ne.statusCode}`}
              </Typography>
            )}
            <Box sx={{ marginTop: 2 }}>
              <Button sx={{ marginRight: 2 }} onClick={() => navigate(-1)}>{"GO BACK"}</Button>
              <Button onClick={() => navigate(0)}>{"RELOAD"}</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'none' }}>
        {error.message}
      </Box>
    </Container>
  );
}

export function PatientError({ error }: Props) {
  const navigate = useNavigate();
  const { domain } = useContext(AuthContext);
  const ne = error.networkError ? error.networkError as ServerError : error.networkError;

  if (hasNotFoundError(error)) {
    navigate(`/${domain}/404`);
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 8 }}>
        <Grid container spacing={2}>
          <Grid item md={2}>
            <ErrorOutline sx={{ fontSize: 80 }} />
          </Grid>
          <Grid item md={10}>
            <Typography variant="h4" component="div">
              {"技術的な問題が発生しています。"}
            </Typography>
            <Typography variant="subtitle1" component="div">
              {"ご迷惑おかけしております。"}
            </Typography>
            {ne && (
              <Typography variant="h1" component="div">
                {`HTTP ERROR ${ne.statusCode}`}
              </Typography>
            )}
            <Box sx={{ marginTop: 2 }}>
              <Button sx={{ marginRight: 2 }} onClick={() => navigate(-1)}>{"戻る"}</Button>
              <Button onClick={() => navigate(0)}>{"更新"}</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'none' }}>
        {error.message}
      </Box>
    </Container>
  );
}

export function hasInvalidRecordError(error: ApolloError) {
  return error.graphQLErrors.some(e => e.extensions && e.extensions.code === 'INVALID_RECORD');
}

export function hasNotFoundError(error: ApolloError) {
  return error.graphQLErrors.some(e => e.extensions && e.extensions.code === 'NOT_FOUND');
}