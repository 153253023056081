import React, { Dispatch } from 'react';
import { Box, TextField } from "@mui/material";
import { AnswerAction } from "../../pages/Answer";
import { TextAnswer } from "../../types/answer";

export function EditableText() {
  return (
    <Box>
      <TextField fullWidth disabled placeholder="Answer Text" />
    </Box>
  );
}

export const PreviewText = ({ answer }: { answer?: TextAnswer }) => (
  <Box>
    <TextField fullWidth multiline value={answer?.value} />
  </Box>
);

type InputtableProps = { answer: TextAnswer, dispatch: Dispatch<AnswerAction> };

export const InputtableText = ({ answer, dispatch }: InputtableProps) => (
  <Box>
    <TextField
      fullWidth
      value={answer.value}
      onChange={e => dispatch({ type: "update", answer: {...answer, value: e.target.value}})}
    />
  </Box>
);