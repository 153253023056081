import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { PreviewQuestionnaire } from "../components/PreviewQuestionnaire";
import { useCompletedQuestionnaireQuery } from "../types/graphql";
import { AuthContext } from "../contexts/AuthContext";
import { Patient } from "../types/user";
import { Loading } from "../components/Loading";
import { PatientError } from "../components/Error";
import { Questionnaire } from "../types/questionnaire";
import { from } from "../types/question";
import { from as fromAnswer } from "../types/answer";

export function Preview() {
  const { completedId } = useParams<{ completedId: string }>();
  const { user } = useContext(AuthContext);
  const u = user as Patient;
  const { data, loading, error } = useCompletedQuestionnaireQuery({
    variables: { clientId: u.clientId, pin: u.pin, id: completedId! }
  });

  if (loading) {
    return <Loading open={loading} />;
  }

  if (error) {
    return <PatientError error={error} />;
  }

  const q = data!.eproCompletedQuestionnaire.eproSchedule.eproQuestionnaire;
  const questionnaire: Questionnaire = {
    id: Number(q.id),
    label: q.label,
    questions: q.eproQuestions.map(from)
  };
  const answers = data!.eproCompletedQuestionnaire.eproAnswers.map(fromAnswer);

  return (
    <PreviewQuestionnaire questionnaire={questionnaire} answers={answers} />
  );
}
