import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  styled
} from "@mui/material";
import {
  Menu as MenuIcon,
} from "@mui/icons-material";
import { DesignerAccountMenu } from "./DesignerAccountMenu";
import { drawerWidth } from "./DesignerMenuDrawer";

const TopBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<{ open: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  })
}));

const MenuDrawerButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'open'
})<{ open: boolean }>(({ theme, open }) => ({
    marginRight: 2,
  ...(open && { display: 'none' })
}));

type DesignerAppBarProps = {
  open: boolean,
  toggleDrawer: () => void
};

export const DesignerAppBar = ({ open, toggleDrawer }: DesignerAppBarProps) => (
  <TopBar position="absolute" open={open}>
    <Toolbar>
      <MenuDrawerButton
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer}
        open={open}
        size="large"
      >
        <MenuIcon />
      </MenuDrawerButton>
      <Typography noWrap component="h1" variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
        Ptosh ePro Designer
      </Typography>
      <DesignerAccountMenu />
    </Toolbar>
  </TopBar>
);
