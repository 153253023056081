import React from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  Container,
} from "@mui/material";
import { PatientTheme } from '../components/Theme';
import { PatientAppBar } from "../components/PatientAppBar";

export const PatientLayout = () => {
  return (
    <PatientTheme>
      <Box flexGrow={1}>
        <PatientAppBar />
        <Box component="main">
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Outlet />
          </Container>
        </Box>
      </Box>
    </PatientTheme>
  );
};
