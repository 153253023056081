import React, { ReactNode } from "react";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  LinkProps,
  ThemeOptions,
} from "@mui/material";
import { teal, deepPurple } from "@mui/material/colors"
import { PropType } from "../types/core";
import { LinkBehavior } from "./LinkBehavior";

const components: PropType<ThemeOptions, 'components'> = {
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
    } as LinkProps
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LinkBehavior,
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard'
    }
  },
  MuiSelect: {
    defaultProps: {
      variant: 'standard'
    }
  }
};

const patientTheme = createTheme({
  components,
  palette: {
    primary: teal,
    secondary: deepPurple
  },
});

export const PatientTheme = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={responsiveFontSizes(patientTheme)}>
    {children}
  </ThemeProvider>
);

const designerTheme = createTheme({ components });

export const DesignerTheme = ({ children }: { children: ReactNode }) => (
  <ThemeProvider theme={responsiveFontSizes(designerTheme)}>
    {children}
  </ThemeProvider>
);
