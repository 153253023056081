import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import { ApolloProvider } from "@apollo/client";
import { Client } from "./api/apollo";
import { Public, RequireDesignerAuth, RequirePatientAuth } from "./components/Route";
import { NotFound } from "./pages/NotFound";
import { AuthContextProvider } from "./contexts/AuthContext";
import { ToastContextProvider } from "./contexts/ToastContext";
import { Login } from "./pages/Login";
import { Top } from "./pages/Top";
import { Answer } from "./pages/Answer";
import { Preview } from "./pages/Preview";
import { AccountSettings } from "./pages/AccountSettings";
import { DesignerLayout } from './pages/DesignerLayout';
import { DesignerLogin } from "./pages/DesignerLogin";
import { Questionnaires } from "./pages/Questionnaires";
import { TrialMenu } from './pages/TrialMenu';
import { QuestionnaireEditor } from "./pages/QuestionnaireEditor";
import { Scheduler } from "./pages/Scheduler";
import { Reminder } from "./pages/Reminder"
import { PatientLayout } from './pages/PatientLayout';

function App() {
  return (
    <AuthContextProvider>
      <ToastContextProvider>
        <ApolloProvider client={Client}>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Router>
              <Routes>
                <Route path="/:domain/404" element={<NotFound />} />
                <Route path="/:domain/designer/login" element={<Public />}>
                  <Route index element={<DesignerLogin />} />
                </Route>
                <Route path="/:domain/designer/*" element={<RequireDesignerAuth />}>
                  <Route path="*" element={<DesignerLayout />}>
                    <Route path="trials/:trial/*" element={<TrialMenu />}>
                      <Route path="questionnaires/*">
                        <Route index element={<Questionnaires />} />
                        <Route path=":questionnaireId" element={<QuestionnaireEditor />} />
                      </Route>
                      <Route path="scheduler" element={<Scheduler />} />
                      <Route path="reminder" element={<Reminder />} />
                    </Route>
                  </Route>
                </Route>
                <Route path="/:domain/login" element={<Public />}>
                  <Route index element={<Login />} />
                </Route>
                <Route path="/:domain/*" element={<RequirePatientAuth />}>
                  <Route path="*" element={<PatientLayout />}>
                    <Route index element={<Top />} />
                    <Route path="settings" element={<AccountSettings />} />
                    <Route path="questionnaires/:incompleteId/answer" element={<Answer />} />
                    <Route path="questionnaires/:completedId/preview" element={<Preview />} />
                  </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Router>
          </Box>
        </ApolloProvider>
      </ToastContextProvider>
    </AuthContextProvider>
  );
}

export default App;
