import React, { useContext, useState } from "react";
import { Box, TextField, Paper, Button } from "@mui/material";
import { PaperTitle } from "../components/PaperTitle";
import { AuthContext } from "../contexts/AuthContext";
import { Patient } from "../types/user";
import { ToastContext } from "../contexts/ToastContext";
import { PatientError } from "../components/Error";
import { AccountQuery, Exact, useAccountQuery, useUpdateAccountMutation } from "../types/graphql";
import { ApolloQueryResult } from "@apollo/client";
import { hasInvalidRecordError } from "../components/Error";

type FetchingTrial = (variables?: Partial<Exact<{ clientId: string; pin: string; }>> | undefined) => Promise<ApolloQueryResult<AccountQuery>>;
// TODO: 各値をマスキング
function EmailForm({ account, fetch }: { account: Patient, fetch: FetchingTrial }) {
  const { popUpMessage } = useContext(ToastContext);
  const [edit, setEdit] = useState(false);
  const [email, setEmail] = useState(account.email)
  const [updateEmail] = useUpdateAccountMutation({
    onError: e => {
      const message = hasInvalidRecordError(e)
        ? "メールアドレスの形式が正しくありません。"
        : "問題が発生しました。しばらくしてからもう一度お試しください。"
      popUpMessage("error", message);
    },
    onCompleted: _ => {
      fetch();
      setEdit(false);
      popUpMessage("success", "更新しました。");
    }
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateEmail({ variables: { email, pin: account.pin, clientId: account.clientId }})
  };

  return (
    <Paper sx={{ padding: 2, display: 'flex', overflow: 'auto', flexDirection: 'column' }}>
      <PaperTitle>
        メールアドレス
      </PaperTitle>
      <div>
        <Box sx={{ display: "inline" }}>
          現在のメールアドレスは
        </Box>
        <Box sx={{ fontWeight: "bold", paddingX: 1, display: "inline" }}>
          {account.email}
        </Box>
        <Box sx={{ display: "inline" }}>
          です。
        </Box>
      </div>
      { edit ? (
        <form onSubmit={onSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="current-email"
            autoFocus
            defaultValue={account.email}
            onChange={e => setEmail(e.target.value)}
          />
          <Box sx={{ marginTop: 2 }}>
            <div>
              <Button disabled={!email} sx={{ marginRight: 1 }} variant="contained" color="primary" type="submit">
                保存
              </Button>
              <Button onClick={() => { setEdit(false); setEmail(account.email)}}>
                キャンセル
              </Button>
            </div>
          </Box>
        </form>
      ) : (
        <Box sx={{ marginTop: 2 }}>
          <Button variant="outlined" color="secondary" onClick={() => setEdit(true)}>
            編集
          </Button>
        </Box>
      )}
    </Paper>
  );
}

function PhoneForm({ account, fetch }: { account: Patient, fetch: FetchingTrial }) {
  const { popUpMessage } = useContext(ToastContext);
  const [edit, setEdit] = useState(false);
  const [phone, setPhone] = useState(account.phone)
  const [updateEmail] = useUpdateAccountMutation({
    onError: _ => popUpMessage("error", "問題が発生しました。しばらくしてからもう一度お試しください。"),
    onCompleted: _ => {
      fetch();
      setEdit(false);
      popUpMessage("success", "更新しました。");
    }
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateEmail({ variables: { phone, pin: account.pin, clientId: account.clientId }})
  };

  return (
    <Paper sx={{ padding: 2, display: 'flex', overflow: 'auto', flexDirection: 'column' }}>
      <PaperTitle>
        電話番号
      </PaperTitle>
      <div>
        <Box sx={{ display: "inline" }}>
          現在の電話番号は
        </Box>
        <Box sx={{ fontWeight: "bold", paddingX: 1, display: "inline" }}>
          {account.phone}
        </Box>
        <Box sx={{ display: "inline" }}>
          です。
        </Box>
      </div>
      { edit ? (
        <form onSubmit={onSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="phone"
            name="phone"
            autoComplete="current-phone"
            autoFocus
            defaultValue={account.phone}
            onChange={e => setPhone(e.target.value)}
          />
          <Box sx={{ marginTop: 2 }}>
            <div>
              <Button disabled={!phone} sx={{ marginRight: 1 }} variant="contained" color="primary" type="submit">
                保存
              </Button>
              <Button onClick={() => { setEdit(false); setPhone(account.email)}}>
                キャンセル
              </Button>
            </div>
          </Box>
        </form>
      ) : (
        <Box sx={{ marginTop: 2 }}>
          <Button variant="outlined" color="secondary" onClick={() => setEdit(true)}>
            編集
          </Button>
        </Box>
      )}
    </Paper>
  )
}

export function AccountSettings() {
  const { user, setUser } = useContext(AuthContext);
  const [account, setAccount] = useState(user as Patient);
  const { error, refetch } = useAccountQuery({
    fetchPolicy: "cache-and-network",
    variables: { pin: account.pin, clientId: account.clientId },
    onCompleted: d => {
      const a = d.eproAccount;
      if (a) {
        const p: Patient = { id: a.id, clientId: a.clientId, pin: a.pin, email: a.email, phone: a.phone };
        setUser(p);
        setAccount(p);
      }
    }
  });

  if (error) {
    return <PatientError error={error} />;
  }

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <EmailForm account={account} fetch={refetch} />
      </Box>
      <Box sx={{ marginBottom: 2 }}>
        <PhoneForm account={account} fetch={refetch} />
      </Box>
    </>
  )
}