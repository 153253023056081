import React, { useState, useContext } from "react";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import * as Store from "../api/cache";
import {
  Box,
  Button,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Edit,
  MoreVert,
  DeleteForever,
  AddCircleOutline,
  Visibility
} from "@mui/icons-material";
import {
  useCreateQuestionnaireMutation,
  useDeleteQuestionnaireMutation,
  useQuestionnairesQuery
} from "../types/graphql";
import { Loading } from "../components/Loading";
import { ToastContext } from "../contexts/ToastContext";
import { DesignerError } from "../components/Error";

const CreateQuestionnaireButton = ({ trial }: { trial: string }) => {
  const navigate = useNavigate();
  const [createQuestionnaire, { loading, error }] = useCreateQuestionnaireMutation({
    variables: { trial },
    onCompleted: data => {
      navigate(`${data.createEproQuestionnaire.questionnaire.id}`);
    },
    update: (cache, { data }) => {
      if (!data) {
        return;
      }
      const cached = Store.readQuestionnaires(cache, { trial })?.eproQuestionnaires || [];
      Store.writeQuestionnaires(
        cache,
        { trial },
        { eproQuestionnaires: [...cached, data.createEproQuestionnaire.questionnaire] }
      );
    }
  });

  if (loading) {
    return <Loading open={loading} />
  }

  if (error) {
    return <DesignerError error={error} />;
  }

  return (
    <IconButton onClick={() => createQuestionnaire()} size="large">
      <AddCircleOutline fontSize="large" />
    </IconButton>
  );
}

const DeletingDialog = ({ trial, open, setOpen, id, label }: {
  trial: string,
  open: boolean,
  setOpen: (_: boolean) => void,
  id: string | undefined,
  label: string | undefined
}) => {
  const { popUpMessage } = useContext(ToastContext);
  const [deleteQuestionnaire] = useDeleteQuestionnaireMutation({
    onCompleted: _ => popUpMessage("success", "Deleted successfully."),
    onError: _ => popUpMessage("error", "Delete failed."),
    update: (cache, { data }) => {
      if (!data) {
        return;
      }
      const cached = Store.readQuestionnaires(cache, { trial });
      const filtered = {
        eproQuestionnaires: cached?.eproQuestionnaires.filter(q => q.id !== data.deleteEproQuestionnaire.id) || []
      };
      Store.writeQuestionnaires(cache, { trial }, filtered);
    }
  });

  if (!id || !label) {
    return <></>;
  }

  const onDelete = () => {
    deleteQuestionnaire({ variables: { id }});
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{"Are you sure you want to delete it?"}</DialogTitle>
      <DialogContent>
        {label}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} autoFocus>CANCEL</Button>
        <Button onClick={onDelete} color="error">DELETE</Button>
      </DialogActions>
    </Dialog>
  );
}

export function Questionnaires() {
  const location = useLocation();
  const { trial } = useParams<{ trial: string }>();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data, loading, error } = useQuestionnairesQuery({ variables: { trial: trial! }});

  if (loading) {
    return <></>;
  }

  if (error) {
    return <DesignerError error={error} />;
  }

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        {(data?.eproQuestionnaires || []).map(q => (
          <Grid key={q.id} item xs={12} sm={6}>
            <Paper>
              <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
                <Typography align="center" noWrap>{q.label}</Typography>
                {q.canEdit ? (
                  <>
                    <IconButton
                      component={Link}
                      to={`${location.pathname}/${q.id}`}
                      sx={{ marginLeft: 'auto' }}
                      size="large">
                      <Edit fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="more"
                      aria-controls={`menu-${q.id}`}
                      aria-haspopup="true"
                      onClick={e => { setAnchorEl(e.currentTarget); setSelectedId(q.id); }}
                      size="large">
                      <MoreVert fontSize="small" />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    component={Link}
                    to={`${location.pathname}/${q.id}`}
                    sx={{ marginLeft: 'auto' }}
                    size="large">
                    <Visibility fontSize="small" />
                  </IconButton>
                )}

                <Menu
                  id={`menu-${q.id}`}
                  keepMounted
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() =>  { setAnchorEl(null); setSelectedId(undefined); }}
                >
                  <MenuItem onClick={() => { setAnchorEl(null); setOpenDialog(true) }}>
                    <ListItemIcon>
                      <DeleteForever color="error" />
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                  </MenuItem>
                </Menu>
              </Box>
            </Paper>
          </Grid>
        ))}

        <Grid item xs={12} sm={6}>
          <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <CreateQuestionnaireButton trial={trial!} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} />
      </Grid>
      <DeletingDialog
        trial={trial!}
        open={openDialog}
        setOpen={setOpenDialog}
        id={selectedId}
        label={data?.eproQuestionnaires.find(q => q.id === selectedId)?.label}
      />
    </div>
  );
}
