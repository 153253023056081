import React, { useContext, useEffect } from 'react';
import {
  Outlet,
  Navigate,
  useParams,
  useLocation
} from "react-router-dom";
import { isLoggedInPatient, isLoggedInDesignerUser } from "../types/user";
import { AuthContext } from "../contexts/AuthContext";

const Domain = ({ children }: { children: React.ReactNode }) => {
  const { setDomain } = useContext(AuthContext);
  const { domain } = useParams<{ domain: string }>();
  useEffect(() => {
    if (domain) {
      setDomain(domain);
    }
  }, [setDomain, domain]);
  return <>{children}</>;
};

export const RequirePatientAuth = () => {
  const { user, domain } = useContext(AuthContext);
  const location = useLocation();
  const pathname = `/${domain}/login`;
  return isLoggedInPatient(user) ? <Domain><Outlet /></Domain> : <Navigate replace to={{ pathname }} state={{ from: location }} />
}

export const RequireDesignerAuth = () => {
  const { user, domain } = useContext(AuthContext);
  const location = useLocation();
  const pathname = `/${domain}/designer/login`;
  return isLoggedInDesignerUser(user) ? <Domain><Outlet /></Domain> : <Navigate replace to={{ pathname }} state={{ from: location }} />
}

export const Public = () => (
  <Domain><Outlet /></Domain>
);
