import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useApolloClient } from "@apollo/client";
import { AuthContext } from "../contexts/AuthContext";

export const PatientAppBar = () => {
  const navigate = useNavigate();
  const { setUser, domain } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const client = useApolloClient();

  const onLogout = () => {
    setUser(null);
    client.clearStore();
    navigate(`/${domain}/login`);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          component={Link}
          to={`/${domain}/`}
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, textDecoration: "none" }}
        >
          ePro
        </Typography>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={e => setAnchorEl(e.currentTarget)}
          color="inherit"
          sx={{ marginLeft: "auto" }}
          size="large"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          keepMounted
          anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
          transformOrigin={{ vertical: 'top', horizontal: 'right', }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem component={Link} to={`/${domain}/settings`} onClick={handleClose}>
            ユーザー設定
          </MenuItem>
          <MenuItem onClick={onLogout}>ログアウト</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}
