import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { AuthContext } from "../contexts/AuthContext";
import { Client } from "../api/apollo";

const LogoutMenuItem = () => {
  const navigate = useNavigate();
  const { setUser, domain } = useContext(AuthContext);
  const onLogout = () => {
    setUser(null);
    Client.clearStore()
    navigate(`/${domain}/designer/login`);
  };

  return (<MenuItem onClick={onLogout}>Logout</MenuItem>);
}

export const DesignerAccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={e => setAnchorEl(e.currentTarget)}
        color="inherit"
        sx={{ marginLeft: 'auto' }}
        size="large">
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <LogoutMenuItem />
      </Menu>
    </>
  )
}