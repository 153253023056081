import { ApolloCache, InMemoryCache } from '@apollo/client';
import {
  QuestionnairesQuery,
  QuestionnairesQueryVariables,
  QuestionnairesDocument,
  SchedulerDocument,
  SchedulerQuery,
  SchedulerQueryVariables,
  RemindersDocument,
  RemindersQuery,
  RemindersQueryVariables,
} from "../types/graphql";

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        eproSchedules: {
          merge: false
        },
        eproQuestionnaires: {
          merge: false
        },
        eproQuestions: {
          merge: false
        }
      }
    },
  }
});

export function readQuestionnaires<T>(cache: ApolloCache<T>, variables: QuestionnairesQueryVariables) {
  return cache.readQuery<QuestionnairesQuery, QuestionnairesQueryVariables>({
    query: QuestionnairesDocument,
    variables
  });
}

export function writeQuestionnaires<T>(cache: ApolloCache<T>, variables: QuestionnairesQueryVariables, data: QuestionnairesQuery) {
  return cache.writeQuery<QuestionnairesQuery, QuestionnairesQueryVariables>({
    query: QuestionnairesDocument,
    variables,
    data
  });
}

export function readScheduler<T>(cache: ApolloCache<T>, variables: SchedulerQueryVariables) {
  return cache.readQuery<SchedulerQuery, SchedulerQueryVariables>({
    query: SchedulerDocument,
    variables
  });
}

export function writeScheduler<T>(cache: ApolloCache<T>, variables: SchedulerQueryVariables, data: SchedulerQuery) {
  return cache.writeQuery<SchedulerQuery, SchedulerQueryVariables>({
    query: SchedulerDocument,
    variables,
    data
  });
}

export function readReminders<T>(cache: ApolloCache<T>, variables: RemindersQueryVariables) {
  return cache.readQuery<RemindersQuery, RemindersQueryVariables>({
    query: RemindersDocument,
    variables
  });
}

export function writeReminders<T>(cache: ApolloCache<T>, variables: RemindersQueryVariables, data: RemindersQuery) {
  return cache.writeQuery<RemindersQuery, RemindersQueryVariables>({
    query: RemindersDocument,
    variables,
    data
  });
}
