import React, { useState } from "react";
import { Link, useParams, useLocation, Outlet } from "react-router-dom";
import { Box, Container, Typography, Tabs, Tab } from "@mui/material";
import { useTrialQuery } from "../types/graphql";
import { DesignerError } from "../components/Error";
import * as Seq from "../types/seq";

export function TrialMenu() {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(Seq.last(location.pathname.split("/").slice(0, 6)));
  const { trial } = useParams<{ trial: string }>();
  const { data, loading, error } = useTrialQuery({ variables: { name: trial! } });
  const handleSelectedTabChange = (_event: React.SyntheticEvent, value: string) => {
    setSelectedTab(value);
  };

  if (loading) {
    return null;
  }

  if (error) {
    return <DesignerError error={error} />;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography noWrap variant="h2" sx={theme => ({ padding: theme.spacing(3) })}>
        {`${data!.trial.organization.name} - ${data!.trial.name}`}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleSelectedTabChange}>
          <Tab
            disableRipple
            component={Link}
            label="Questionnaires"
            to="questionnaires"
            value="questionnaires"
          />
          <Tab
            disableRipple
            component={Link}
            label="Scheduler"
            to="scheduler"
            value="scheduler"
          />
          <Tab
            disableRipple
            component={Link}
            label="Reminder"
            to="reminder"
            value="reminder"
          />
        </Tabs>
      </Box>
      <Container sx={theme => ({ padding: theme.spacing(4, 3) })}>
        <Outlet />
      </Container>
    </Box>
  );
}
