import React, { Dispatch } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  Slider as FSlider
} from "@mui/material";
import { Slider } from "../../types/question";
import { QuestionsAction } from '../EditableQuestionnaire';
import { AnswerAction } from "../../pages/Answer";
import { SliderAnswer } from "../../types/answer";

type Props = { id: string, question: Slider, dispatch: Dispatch<QuestionsAction> };

export function EditableSlider({ id, question, dispatch }: Props) {
  return (
    <Box>
      <Grid container wrap="nowrap" direction="row" alignItems="center">
        <Grid item>
          <TextField
            label="Min"
            type="number"
            value={question.min}
            onChange={e => dispatch({ type: "update", id, value: {...question, min: Number(e.target.value)}})}
          />
        </Grid>
        <Grid item>
          <Box sx={{ marginX: 2 }}>
            <Typography>〜</Typography>
          </Box>
        </Grid>
        <Grid item>
          <TextField
            label="Max"
            type="number"
            value={question.max}
            onChange={e => dispatch({ type: "update", id, value: {...question, max: Number(e.target.value)}})}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginY: 4 }}>
        <TextField
          label="Step"
          value={question.step}
          type="number"
          onChange={e => dispatch({ type: "update", id, value: {...question, step: Number(e.target.value)} })}
        />
      </Box>
      <Box sx={{ marginY: 4 }}>
        <TextField
          label="Min Label"
          value={question.minLabel}
          onChange={e => dispatch({ type: "update", id, value: {...question, minLabel: e.target.value} })}
        />
      </Box>
      <Box>
        <TextField
          label="Max Label"
          value={question.maxLabel}
          onChange={e => dispatch({ type: "update", id, value: {...question, maxLabel: e.target.value} })}
        />
      </Box>
    </Box>
  );
}

export const PreviewSlider = ({ question, answer }: { question: Slider, answer?: SliderAnswer }) => (
  <Grid container wrap="nowrap" direction="row" alignItems="center" spacing={2}>
    <Grid item xs={12} sm={3}>
      <Typography align="right">{question.minLabel}</Typography>
    </Grid>
    <Grid item xs={12} sm={6}>
      <FSlider
        defaultValue={question.min}
        value={answer?.value}
        min={question.min}
        max={question.max}
        step={question.step}
        valueLabelDisplay={answer ? "on" : "auto"}
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <Typography>{question.maxLabel}</Typography>
    </Grid>
  </Grid>
);

type InputtableProps = { question: Slider, answer: SliderAnswer, dispatch: Dispatch<AnswerAction> };

export const InputtableSlider = ({ question, answer, dispatch }: InputtableProps) => (
  <Grid container wrap="nowrap" direction="row" alignItems="center" spacing={2}>
    <Grid item xs={12} sm={3}>
      <Typography align="right">{question.minLabel}</Typography>
    </Grid>
    <Grid item xs={12} sm={6}>
      <FSlider
        defaultValue={question.min}
        value={answer.value}
        min={question.min}
        max={question.max}
        step={question.step}
        valueLabelDisplay="auto"
        onChange={(_, v) => dispatch({ type: "update", answer: {...answer, value: v as number}})}
      />
    </Grid>
    <Grid item xs={12} sm={3}>
      <Typography>{question.maxLabel}</Typography>
    </Grid>
  </Grid>
);
