import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  IconButton,
  Drawer,
  Divider,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  styled,
  Toolbar
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { DesignerError } from "../components/Error";
import { useTrialsQuery, TrialsQuery,  } from "../types/graphql";
import { PropType } from "../types/core";
import * as Seq from "../types/seq";

export const drawerWidth = 240;

const MenuDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: 0,
    })
  }
}));

type DesignerMenuDrawerProps = {
  open: boolean,
  toggleDrawer: () => void
}

type TrialsGroupedByOrganizationNameType = Map<string, PropType<TrialsQuery, 'trials'>>;

export const DesignerMenuDrawer = ({ open, toggleDrawer }: DesignerMenuDrawerProps) => {
  const { trial: trialName } = useParams<{ trial: string }>();
  const [values, setValues] = useState<TrialsGroupedByOrganizationNameType>(new Map());
  const [organizations, setOrganizations] = useState<string[]>([])
  const { error } = useTrialsQuery({
    onCompleted: d => {
      const xs = Seq.groupBy(d.trials.filter(t => t.isEproAvailable), t => t.organization.name);
      setValues(xs);
      setOrganizations([...xs.keys()].sort());
    }
  });

  if (error) {
    return <DesignerError error={error} />;
  }

  return (
    <MenuDrawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1]
        }}
      >
        <IconButton onClick={toggleDrawer} size="large">
          <ChevronLeft />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {organizations.map(org => (
          <div key={org}>
            <ListSubheader inset disableSticky>{org}</ListSubheader>
            {values.get(org)?.map(trial => (
              <ListItem
                key={`${org} - ${trial.name}`}
                component={Link}
                to={`trials/${trial.name}/questionnaires`}
                button
                selected={trial.name === trialName}
              >
                <ListItemText primary={trial.name} />
              </ListItem>
            ))}
          </div>
        ))}
      </List>
    </MenuDrawer>
  );
}