import React, { ReactNode, useState } from "react";
import { AlertColor } from "@mui/material/Alert";
import { AlertSnackbar } from "../components/AlertSnackbar";

type ToastContextType = {
  popUpMessage: (severity: AlertColor, message: string) => void;
};

const initialState: ToastContextType = { popUpMessage: (_severity: AlertColor, _message: string) => {} };

export const ToastContext = React.createContext(initialState);

export const ToastContextProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [alertSeverity, setSeverity] = useState<AlertColor>('info');
  const [alertMessage, setMessage] = useState('');
  const popUpMessage = (severity: AlertColor, message: string) => {
    setOpen(true);
    setSeverity(severity);
    setMessage(message);
  };

  return (
    <ToastContext.Provider value={({ popUpMessage })}>
      <AlertSnackbar
        open={open}
        severity={alertSeverity}
        message={alertMessage}
        onClose={() => setOpen(false)}
      />
      {children}
    </ToastContext.Provider>
  );
}
