import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from "@mui/material";
import { Questionnaire } from "../types/questionnaire";
import { Question } from "../types/question";
import { Answer, match } from "../types/answer";
import { PreviewText } from "./questions/Text";
import { PreviewRadio } from "./questions/Radio";
import { PreviewCheckBox } from "./questions/CheckBox";
import { PreviewSlider } from "./questions/Slider";

const InputtableQuestion = ({ question, answer }: { question: Question, answer: Answer }) => (
  <Card>
    <CardContent>
      <Typography gutterBottom variant="subtitle1">{question.label}</Typography>
      { match(
          answer,
          ans => <PreviewText answer={ans} />,
          ans => <PreviewRadio question={question as any} answer={ans} />,
          ans => <PreviewCheckBox question={question as any} answer={ans}/>,
          ans => <PreviewSlider question={question as any} answer={ans} />
      )}
    </CardContent>
  </Card>
);

export const PreviewQuestionnaire = ({ questionnaire, answers }: { questionnaire: Questionnaire, answers: Answer[] }) => (
  <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
    <Grid item xs={12}>
      <Box sx={{ marginY: 2 }}>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5">
              {questionnaire.label}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      { questionnaire.questions.map(q => (
        <Box key={q.id} sx={{ marginY: 2 }}>
          <InputtableQuestion
            question={q}
            answer={answers.find(a => a.questionId === q.id!)!}
          />
        </Box>
      ))}
    </Grid>
  </Grid>
);
