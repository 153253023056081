import { utcToZonedTime } from 'date-fns-tz'

const timeZone = "Asia/Tokyo";

export function jstNow(): Date {
  return utcToZonedTime(new Date(), timeZone);
}

export function jstNowWithoutTime(): Date {
  return new Date(jstNow().toDateString());
}
