import { ApolloClient, ServerError, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { parse, isPatient } from '../types/user';
import { clearStorage } from '../contexts/AuthContext';
import { cache } from './cache';

const urlLink = createHttpLink({ fetch: (_uri, options) => {
  const domain = localStorage.getItem('ptosh-domain');
  return fetch(`https://${domain}/api/graphql`, options);
}});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`
        [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}
      `);
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);

    if ((networkError as ServerError).statusCode === 401) {
      clearStorage();
      // TODO: https://github.com/apollographql/apollo-client/issues/6758
      // TODO: https://github.com/apollographql/apollo-client/issues/6070
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const item = localStorage.getItem('ptosh') || null;
  if (!item) {
    return headers;
  }
  const user = parse(item);
  if (isPatient(user)) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      'access-token': user.authToken?.token,
      client: user.authToken?.client,
      uid: user.authToken?.uid
    }
  };
});

const link = from([
  authLink,
  errorLink,
  urlLink
]);

export const Client = new ApolloClient({
  link,
  cache
});
