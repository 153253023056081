import { Axios } from "./axios";
import { DesignerUser } from "../types/user";

type AuthError = "Unauthorized" | "InternalServerError";

export async function authenticateDesignerUser(email: string, password: string): Promise<DesignerUser | AuthError> {
  const domain = localStorage.getItem('ptosh-domain');
  const url = `https://${domain}/api/auth/sign_in`;
  const result = await Axios.post(url, { email, password }).catch<AuthError>(e => {
    if (e?.response?.status === 401) {
      return "Unauthorized";
    }
    return "InternalServerError";
  });

  if (result === "Unauthorized" || result === "InternalServerError") {
    return result;
  }

  const d = result.data.data;
  return {
    email: d.email,
    id: d.id,
    firstName: d.firstname,
    lastName: d.lastname,
    firstNameEn: d.firstname_en,
    lastNameEn: d.lastname_en,
    uuid: d.uuid,
    role: d.role,
    authToken: {
      uid: result.headers['uid'],
      client: result.headers['client'],
      token: result.headers['access-token']
    }
  }
}
