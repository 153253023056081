import { v4 as uuid } from 'uuid';
import React, { Dispatch, useState, useReducer, useContext } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Card,
  CardContent,
  Checkbox,
  Grid,
  List,
  ListItem,
  Fab,
  Tooltip,
  Snackbar,
  Drawer,
  Divider,
  Typography,
  ListItemText,
} from "@mui/material";
import {
  AddCircleOutline,
  SaveAlt,
  Close,
  Input
} from "@mui/icons-material"
import { EditableQuestion } from "./EditableQuestion";
import { Question, from, toParams } from "../types/question";
import * as Seq from "../types/seq";
import {
  QuestionKind,
  QuestionnaireQuery,
  UpdateQuestionnaireMutation,
  useUpdateQuestionnaireMutation,
  useQuestionnaireQuery
} from "../types/graphql";
import { ToastContext } from "../contexts/ToastContext";
import { QuestionnaireSelectorDialog } from "./QuestionnaireSelectorDialog";

type QuestionProps = { question: Question, id: string };
type State = {
  prev: QuestionProps[],
  current: QuestionProps[],
  editingId: string,
  openUndo: boolean,
  undoMessage: string
};

export type QuestionsAction =
  { type: "add" }
  | { type: "copy" }
  | { type: "remove", id: string }
  | { type: "update", id: string, value: Question }
  | { type: "import", values: Question[] }
  | { type: "undo" }
  | { type: "close_undo" }
  | { type: "update_editing", id: string }
  | { type: "reorder", start: number, end: number }
  | { type: "save", result: UpdateQuestionnaireMutation };

const initialState: State = { prev: [], current: [], editingId: "form-name", openUndo: false, undoMessage: "" };
function init(initialValue: QuestionnaireQuery): State {
  const questions = initialValue.eproQuestionnaire.eproQuestions.map(from);
  return { ...initialState, current: questions.map(question => ({ id: uuid(), question })) };
}
const reorder = (questions: QuestionProps[]) => questions.map((q, i) => ({...q, question: {...q.question, seq: i}}));
function reducer(state: State, action: QuestionsAction): State {
  switch(action.type) {
    case "add":
      const v: QuestionProps = { id: uuid(), question: { id: null, label: "", seq: 0, type: QuestionKind.Text } };
      if (state.editingId === "form-name") {
        return {
          ...state,
          current: reorder([v, ...state.current]),
          openUndo: false,
          editingId: v.id
        };
      }
      return {
        ...state,
        current: reorder(state.current.reduce<QuestionProps[]>((acc, q) => {
          acc.push(q);
          if (q.id === state.editingId) {
            acc.push(v);
          }
          return acc;
        }, [])),
        openUndo: false,
        editingId: v.id
      };
    case "copy":
      const id = uuid();
      return {
        ...state,
        current: reorder(state.current.reduce<QuestionProps[]>((acc, q) => {
          acc.push(q);
          if (q.id === state.editingId) {
            acc.push({ id, question: { ...q.question, id: null } });
          }
          return acc;
        }, [])),
        openUndo: false,
        editingId: id
      };
    case "remove":
      return {
        ...state,
        prev: state.current,
        current: reorder(state.current.filter(q => q.id !== action.id)),
        openUndo: true,
        undoMessage: "It has been deleted."
      };
    case "update":
      return {
        ...state,
        current: reorder(state.current.map(q => ({ ...q, question: q.id === action.id ? action.value : q.question}))),
        openUndo: false
      };
    case "import":
      const vs = action.values.map(v => ({ id: uuid(), question: v}));
      if (state.editingId === "form-name") {
        return {
          ...state,
          current: reorder([...vs, ...state.current]),
          prev: state.current,
          openUndo: true,
          undoMessage: "It has been imported."
        };
      }
      return {
        ...state,
        current: reorder(state.current.reduce<QuestionProps[]>((acc, q) => {
          acc.push(q);
          if (q.id === state.editingId) {
            vs.forEach(v => acc.push(v));
          }
          return acc;
        }, [])),
        prev: state.current,
        openUndo: true,
        undoMessage: "It has been imported."
      };
    case "undo":
      return { ...state, current: state.prev, openUndo: false };
    case "close_undo":
      return { ...state, openUndo: false };
    case "update_editing":
      return { ...state, editingId: action.id };
    case "reorder":
      const current = reorder(Seq.swap(state.current, action.start, action.end))
      return { ...state, current, editingId: current[action.end].id, openUndo: false };
    case "save":
      const qs = action.result.updateEproQuestionnaire.questionnaire.eproQuestions.map(q => ({ id: uuid(), question: from(q) }));
      return { ...state, current: qs.sort((l, r) => l.question.seq - r.question.seq), openUndo: false };
  }
}

const ActionButtons = ({ dispatch, openImportDialog }: { dispatch: Dispatch<QuestionsAction>, openImportDialog: () => void }) => {
  return (
    <Grid item xs={1}>
      <Box sx={{ marginY: 2 }}>
        <Card>
          <List component="nav">
            <Tooltip title="Add" aria-label="add" placement="right" >
              <ListItem button sx={{ minWidth: 0, flexFlow: 'column' }} onClick={() => dispatch({ type: "add" })}>
                <AddCircleOutline fontSize="small" />
              </ListItem>
            </Tooltip>
            <Tooltip title="Import" aria-label="import" placement="right">
              <ListItem button sx={{ minWidth: 0, flexFlow: 'column' }} onClick={openImportDialog}>
                <Input fontSize="small" />
              </ListItem>
            </Tooltip>
          </List>
        </Card>
      </Box>
    </Grid>
  );
};

function ImportQuestionsSelector({ trial, id, onCancel, onImport }: {
  trial: string,
  id: string,
  onCancel: () => void,
  onImport: (questions: Question[]) => void
}) {
  const { popUpMessage } = useContext(ToastContext);
  const [questions, setQuestions] = useState<{ checked: boolean, question: Question }[]>([]);
  useQuestionnaireQuery({
    variables: { trial, id },
    onError: _ => popUpMessage("error", "An error has occurred. Please try again after a while."),
    onCompleted: data => setQuestions(
      data.eproQuestionnaire.eproQuestions
        .map(q => ({ checked: false, question: from(q) }))
        .sort((l, r) => l.question.seq - r.question.seq))
  });

  const toggle = (id: number | null) => () => {
    setQuestions(questions.map(q => q.question.id === id ? {...q, checked: !q.checked} : q))
  };

  return (
    <Drawer open={true} variant="permanent">
      <Box sx={{ margin: 2 }}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Import Questions</Typography>
          </Grid>
          <Grid item>
            <IconButton edge="end" onClick={() => onCancel()} size="large">
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <List>
        <ListItem button onClick={() => setQuestions(questions.map(q => ({...q, checked: !questions.every(q => q.checked)})))}>
          <Checkbox edge="start" checked={questions.every(q => q.checked)} />
          <ListItemText primary="Select all" />
        </ListItem>
        {questions.map(q => (
          <ListItem
            key={q.question.id}
            button
            onClick={toggle(q.question.id)}
          >
            <Checkbox edge="start" disableRipple checked={q.checked} />
            <ListItemText primary={q.question.label} secondary={q.question.type} />
          </ListItem>
        ))}
      </List>
      <Box sx={{ marginX: 2, marginY: 4 }}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => onImport(questions.filter(q => q.checked).map(q => ({...q.question, id: null })))}
        >
          {`Import (${questions.filter(q => q.checked).length})`}
        </Button>
      </Box>
    </Drawer>
  );
}

export function EditableQuestionnaire({ trial, questionnaire }: { trial: string, questionnaire: QuestionnaireQuery }) {
  const { popUpMessage } = useContext(ToastContext);
  const [name, setName] = useState(questionnaire.eproQuestionnaire.label);
  const [state, dispatch] = useReducer(reducer, questionnaire, init);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [importingQuestionnaireId, setImportingQuestionnaireId] = useState<string | null>(null);
  const [updateQuestionnaire] = useUpdateQuestionnaireMutation({
    onCompleted: data => {
      popUpMessage("success", "Saved successfully.");
      dispatch({ type: "save", result: data });
    },
    onError: _ => popUpMessage("error", "Save failed.")
  });
  const onSave = () => {
    updateQuestionnaire({ variables: { input: {
      id: questionnaire.eproQuestionnaire.id,
      label: name,
      eproQuestions: state.current.map(q => toParams(q.question))
    }}});
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }
    dispatch({ type: "reorder", start: result.source.index, end: result.destination.index });
  };

  return (
    <>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={11}>
          <Box
            id="form-name"
            sx={{ marginY: 2 }}
            onClick={() => dispatch({ type: "update_editing", id: "form-name" })}
          >
            <Card>
              <CardContent>
                <TextField
                  fullWidth
                  required
                  label="Title"
                  defaultValue={name}
                  onChange={e => setName(e.target.value)}
                />
              </CardContent>
            </Card>
          </Box>
        </Grid>
        {state.editingId === "form-name" && <ActionButtons dispatch={dispatch} openImportDialog={() => setOpenImportDialog(true)} />}
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="questions">
          {dropProv => (
            <Box ref={dropProv.innerRef} {...dropProv.droppableProps}>
              {state.current.sort((l, r) => l.question.seq - r.question.seq).map((q, i) => (
                <Grid key={q.id} container alignItems="flex-start" spacing={2}>
                  <Grid item xs={11}>
                    <Box
                      id={q.id}
                      sx={{ marginTop: 2 }}
                      onClick={() => dispatch({ type: "update_editing", id: q.id })}
                    >
                      <EditableQuestion {...q} editable={state.editingId === q.id} dispatch={dispatch} index={i} />
                    </Box>
                  </Grid>
                  {state.editingId === q.id && <ActionButtons dispatch={dispatch} openImportDialog={() => setOpenImportDialog(true)} />}
                </Grid>
              ))}
              {dropProv.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      {questionnaire.eproQuestionnaire.canEdit && (
        <Tooltip title="Save" aria-label="save" placement="top">
          <Fab
            color="primary"
            aria-label="save"
            sx={{
              position: "fixed",
              top: "auto",
              left: "auto",
              bottom: "2rem",
              right: "2rem"
            }}
            onClick={onSave}
          >
            <SaveAlt />
          </Fab>
        </Tooltip>
      )}

      <QuestionnaireSelectorDialog
        open={openImportDialog}
        onClose={() => setOpenImportDialog(false)}
        onSelect={id => {
          setOpenImportDialog(false);
          setImportingQuestionnaireId(id);
        }}
      />

      {importingQuestionnaireId && (
        <ImportQuestionsSelector
          trial={trial}
          id={importingQuestionnaireId}
          onCancel={() => setImportingQuestionnaireId(null)}
          onImport={values => { setImportingQuestionnaireId(null); dispatch({ type: "import", values }); }}
        />)
      }

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={state.openUndo}
        autoHideDuration={6000}
        onClose={(_, reason) => {
          if (reason !== "clickaway") {
            dispatch({ type: "close_undo" });
          }
        }}
        message={state.undoMessage}
        action={
          <>
            <Button color="secondary" size="small" onClick={() => dispatch({ type: "undo" })}>
              UNDO
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => dispatch({ type: "close_undo" })}>
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
}
