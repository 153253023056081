import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export const Loading = ({ open }: { open: boolean }) => {
  return (
    <Backdrop
      sx={theme => ({ zIndex: theme.zIndex.drawer + 1, color: '#fff' })}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
