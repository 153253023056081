import React, { ReactNode, useState, useEffect } from "react";
import { User } from "../types/user";

type UserType = User | null;
export type AuthContextType = {
  user: UserType;
  domain: string;
  setUser: (user: UserType) => void;
  setDomain: (domain: string) => void;
  logout: () => void;
}

const initialState: AuthContextType = {
  user: null,
  domain: '',
  setUser: (_user: UserType) => {},
  setDomain: (_domain: string) => {},
  logout: () => {}
};

function readDomain() {
  const d = localStorage.getItem("ptosh-domain") || "";
  return d.replace(/.ptosh.com$/, '').replace(/.com$/, '');
}

export const AuthContext = React.createContext(initialState);
export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserType>(null);
  const [domain, setDomain] = useState(readDomain());

  useEffect(() => {
    const i = localStorage.getItem("ptosh");
    if (i) {
      setUser(JSON.parse(i) as UserType);
    }
  }, []);

  const updateUser = (u: UserType) => {
    if (u) {
      localStorage.setItem("ptosh", JSON.stringify(u));
    } else {
      clearStorage();
    }
    setUser(u);
  };

  const logout = () => {
    clearStorage();
    setUser(null);
  };

  const updateDomain = (d: string) => {
    const f = (s: string) => {
      if (s === 'localhost:3000') {
        return s;
      }
      if (s === 'ptosh') {
        return 'ptosh.com';
      }
      return `${s}.ptosh.com`;
    }
    const path = f(d);
    if (path !== localStorage.getItem("ptosh-domain")) {
      logout();
      localStorage.setItem("ptosh-domain", path);
      setDomain(d);
    }
  };

  return (
    <AuthContext.Provider value={({ user, domain, logout, setUser: updateUser, setDomain: updateDomain })}>
      {children}
    </AuthContext.Provider>
  );
}

export function clearStorage() {
  localStorage.removeItem("ptosh");
}

export function hasAuth(): boolean {
  return !!localStorage.getItem("ptosh");
}
