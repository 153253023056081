import React, { useState, useContext } from "react";
import {
  styled,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material";
import { useTrialsQuery, useQuestionnairesLazyQuery } from "../types/graphql";
import * as Seq from "../types/seq";
import { ToastContext } from "../contexts/ToastContext";

const StyledTableRow = styled(TableRow)({
  cursor: "pointer",
  '&:last-child td, &:last-child th': {
    border: 0
  }
});

type Props = {
  open: boolean,
  onClose: () => void,
  onSelect: (questionnaireId: string) => void
};

export function QuestionnaireSelectorDialog({ open, onClose, onSelect }: Props) {
  const { popUpMessage } = useContext(ToastContext);
  const [selectedTrial, setSelectedTrial] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const { data } = useTrialsQuery({
    onError: _ => popUpMessage("error", "An error has occurred. Please try again after a while.")
  });
  const [getQuestionnaires, result] = useQuestionnairesLazyQuery({
    onError: _ => popUpMessage("error", "An error has occurred. Please try again after a while.")
  });

  const onSubmit = () => {
    if (selectedItem) {
      onClose();
      setSelectedTrial(null);
      setSelectedItem(null);
      onSelect(selectedItem);
    }
  };

  if (!data) {
    return null;
  }

  const orgs = Seq.groupBy(data!.trials, t => t.organization.name);
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      scroll="paper"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Select Questionnaire</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Paper elevation={0} sx={{ maxHeight: 600, overflow: "auto" }}>
              <List>
                { orgs && [...orgs.keys()].sort().map(org => (
                  <div key={org}>
                    <ListSubheader inset disableSticky>{org}</ListSubheader>
                    { orgs.get(org)?.map(trial => (
                      <ListItem
                        key={`${org} - ${trial.name}`}
                        button
                        selected={trial.name === selectedTrial}
                        onClick={() => {
                          if (selectedTrial !== trial.name) {
                            setSelectedItem(null);
                          }
                          setSelectedTrial(trial.name);
                          getQuestionnaires({ variables: { trial: trial.name }});
                        }}
                      >
                        <ListItemText primary={trial.name} />
                      </ListItem>
                    ))}
                  </div>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={9}>
            <TableContainer>
              <Table sx={{ width: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Label</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.data?.eproQuestionnaires?.map(q => (
                    <StyledTableRow
                      key={q.id}
                      hover
                      selected={selectedItem === q.id}
                      onClick={() => setSelectedItem(q.id)}
                    >
                      <TableCell>{q.label}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" disabled={!selectedItem} onClick={onSubmit}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}