export function any(source: any[]): boolean {
  return !!source.length;
}

export function swap<T>(source: T[], start: number, end: number): T[] {
  const result = Array.from(source);
  const [removed] = result.splice(start, 1);
  result.splice(end, 0, removed);
  return result;
}

export function uniq<T>(source: T[]): T[] {
  return [...new Set(source)];
}

export function uniqBy<T, U>(source: T[], selector: (_: T) => U): U[] {
  return uniq(source.map(selector));
}

export function groupBy<T, U>(source: T[], keySelector: (_: T) => U): Map<U, T[]> {
  return source.reduce((acc, value) => {
    const key = keySelector(value);
    if (!acc.has(key)) {
      acc.set(key, []);
    }
    acc.set(key, [...acc.get(key)!, value]);
    return acc;
  }, new Map<U, T[]>());
}

export function mput<T, U>(source: T[], map: (_: T) => U, putting: (_: T) => U): U[] {
  return source.reduce<U[]>((acc, value, i) => {
    acc.push(map(value));
    if (source.length - 1 !== i) {
      acc.push(putting(value));
    }
    return acc;
  }, []);
}

export function last<T>(source: T[]): T | undefined {
  return source[source.length - 1];
}