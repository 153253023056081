import React, { Dispatch, useContext, useReducer } from "react";
import { useParams, Navigate } from "react-router-dom";
import { parseISO } from 'date-fns'
import { jstNowWithoutTime } from "../types/date";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from "@mui/material";
import { InputtableText } from "../components/questions/Text";
import { InputtableRadio } from "../components/questions/Radio";
import { InputtableCheckBox } from "../components/questions/CheckBox";
import { InputtableSlider } from "../components/questions/Slider";
import { useCreateCompletedQuestionnaireMutation } from "../types/graphql";
import { ToastContext } from "../contexts/ToastContext";
import { useIncompleteQuestionnaireQuery } from "../types/graphql";
import { AuthContext } from "../contexts/AuthContext";
import { Patient } from "../types/user";
import { Loading } from "../components/Loading";
import { Questionnaire } from "../types/questionnaire";
import { Question } from "../types/question";
import { Answer as AnswerType, match, init as initAnswer, toParams, fromSnapshot } from "../types/answer";
import { PatientError } from "../components/Error";
import { from } from "../types/question";

type Snapshot = { questionId: number, value: any }[];
type State = { questionnaire: Questionnaire, answers: AnswerType[] };
export type AnswerAction = { type: "update", answer: AnswerType };

function init({ questionnaire, snapshot }: { questionnaire: Questionnaire, snapshot: Snapshot }): State {
  if (snapshot.length) {
    const answers = snapshot.map(s => {
      const question = questionnaire.questions.find(q => q.id === s.questionId);
      return fromSnapshot(question!, s.value.value);
    })
    return { questionnaire, answers };
  }
  return { questionnaire, answers: questionnaire.questions.map(initAnswer) };
}

function reducer(state: State, action: AnswerAction) {
  switch(action.type) {
    case "update":
      return {
        ...state,
        answers: state.answers.map(a => a.questionId === action.answer.questionId ? action.answer : a)
      };
  }
}

const InputtableQuestion = ({ dispatch, question, answer }: {
  dispatch: Dispatch<AnswerAction>,
  question: Question,
  answer: AnswerType
}) => (
  <Card>
    <CardContent>
      <Typography gutterBottom variant="subtitle1">{question.label}</Typography>
      { match(
          answer,
          ans => <InputtableText dispatch={dispatch} answer={ans} />,
          ans => <InputtableRadio question={question as any} dispatch={dispatch} answer={ans} />,
          ans => <InputtableCheckBox question={question as any} dispatch={dispatch} answer={ans}/>,
          ans => <InputtableSlider question={question as any} dispatch={dispatch} answer={ans} />
      )}
    </CardContent>
  </Card>
);

export function InputtableQuestionnaire({
  questionnaire,
  incompleteId,
  snapshot
}: { incompleteId: string, questionnaire: Questionnaire, snapshot: Snapshot }) {
  const { user, domain } = useContext(AuthContext);
  const { popUpMessage } = useContext(ToastContext);
  const [state, dispatch] = useReducer(reducer, { questionnaire, snapshot }, init);
  const [createCompletedQuestionnaire, { data }] = useCreateCompletedQuestionnaireMutation({
    onError: _ => popUpMessage("error", "問題が発生しました。しばらくしてからもう一度お試しください。")
  });

  const onSave = () => {
    const u = user as Patient;
    createCompletedQuestionnaire({ variables: {
      incompleteId,
      clientId: u.clientId,
      pin: u.pin,
      input: state.answers.map(toParams)
    }});
  };

  if (data) {
    return <Navigate to={{ pathname: `/${domain}/`}} state={{ successAnswer: true }} />;
  }

  return (
    <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ marginY: 2 }}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5">
                {state.questionnaire!.label}
              </Typography>
            </CardContent>
          </Card>
        </Box>

        { questionnaire.questions.map(q => (
          <Box key={q.id} sx={{ marginY: 2 }}>
            <InputtableQuestion
              dispatch={dispatch}
              question={q}
              answer={state.answers.find(a => a.questionId === q.id!)!}
            />
          </Box>
        ))}

        <Box sx={{ marginY: 2 }}>
          <Button variant="contained" color="primary" onClick={onSave}>
            送信
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

function parseSnapshot(value: any): Snapshot {
  if (Object.keys(value).length) {
    return value.map((v: any) => ({ questionId: v.epro_question_id, value: v.value }));
  }
  return [];
}

export function Answer() {
  const { incompleteId } = useParams<{ incompleteId: string }>();
  const { user } = useContext(AuthContext);
  const u = user as Patient;
  const { data, loading, error } = useIncompleteQuestionnaireQuery({
    variables: { clientId: u.clientId, pin: u.pin, id: incompleteId! }
  });

  if (loading) {
    return <Loading open={loading} />;
  }

  if (error) {
    return <PatientError error={error} />;
  }

  const now = jstNowWithoutTime();
  const incomplete = data!.eproIncompleteQuestionnaire;
  if (now < parseISO(incomplete.begin) || (incomplete.end && parseISO(incomplete.end) < now)) {
    return <Navigate to="/" state={{ outsidePeriod: true }} />;
  }

  const q = data!.eproIncompleteQuestionnaire.eproSchedule.eproQuestionnaire;
  const questionnaire: Questionnaire = {
    id: Number(q.id),
    label: q.label,
    questions: q.eproQuestions.map(from)
  };

  return (
    <InputtableQuestionnaire
      incompleteId={incompleteId!}
      questionnaire={questionnaire}
      snapshot={parseSnapshot(data!.eproIncompleteQuestionnaire.snapshot)}
    />
  );
}
