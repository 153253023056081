import React, { useContext, useState } from "react";
import { compareAsc, format, parseISO } from 'date-fns'
import { PropType } from "../types/core";
import { jstNowWithoutTime } from "../types/date";
import { Link, useLocation } from "react-router-dom";
import {
  Box,
  IconButton,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  Alert,
} from "@mui/material";
import * as Seq from "../types/seq";
import { QuestionAnswer, Visibility } from "@mui/icons-material";
import { Loading } from "../components/Loading";
import { PaperTitle } from "../components/PaperTitle";
import { AuthContext } from "../contexts/AuthContext";
import { usePatientOverviewQuery, PatientOverviewQuery } from "../types/graphql";
import { Patient } from "../types/user";
import { PatientError } from "../components/Error";

type IncompletesType = PropType<PatientOverviewQuery, "eproIncompleteQuestionnaires">;
type CompletesType = PropType<PatientOverviewQuery, "eproCompletedQuestionnaires">;

export function Top() {
  const { user, domain } = useContext(AuthContext);
  const location = useLocation() as { state: { successAnswer?: boolean, outsidePeriod?: boolean } };
  const u = user as Patient;
  const [incompletes, setIncompletes] = useState<IncompletesType>([]);
  const [completes, setCompletes] = useState<CompletesType>([]);
  const { loading, error } = usePatientOverviewQuery({
    fetchPolicy: 'network-only',
    variables: { clientId: u.clientId, pin: u.pin },
    onCompleted: d => {
      const now = jstNowWithoutTime();
      const incs = d.eproIncompleteQuestionnaires.map(q => ({...q, begin: parseISO(q.begin), end: q.end && parseISO(q.end)}))
        .filter(q => q.end ? q.begin <= now && now <= q.end : q.begin <= now)
        .sort((l, r) => compareAsc(l.end, r.end));
      setIncompletes(incs);
      setCompletes(d.eproCompletedQuestionnaires.slice().sort((l, r) => compareAsc(l.updatedAt, r.updatedAt)));
    }
  })

  if (loading) {
    return <Loading open={loading} />;
  }

  if (error) {
    return <PatientError error={error} />;
  }

  return (
    <>
      {location.state?.successAnswer && (
        <Box sx={{ marginY: 2 }}>
          <Alert severity="success">
            ご回答ありがとうございます。
          </Alert>
        </Box>
      )}
      {location.state?.outsidePeriod && (
        <Box sx={{ marginY: 2 }}>
          <Alert severity="warning">
            回答期間外のアンケートには回答できません。
          </Alert>
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, display: 'flex', overflow: 'auto', flexDirection: 'column' }}>
            <PaperTitle>
              未回答アンケート一覧
            </PaperTitle>
            <List sx={{ width: 'auto'}}>
              {Seq.mput(incompletes,
                q => (
                  <ListItem key={`i${q.id}`} alignItems="flex-start">
                    <ListItemText
                      primary={q.eproSchedule.eproQuestionnaire.label}
                      secondary={`入力期限: ${q.end ? format(q.end, "yyyy/MM/dd") : "無期限"}`}
                      primaryTypographyProps={{ noWrap: true, gutterBottom: true }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        title="回答する"
                        edge="end"
                        component={Link}
                        to={`/${domain}/questionnaires/${q.id}/answer`}
                        size="large">
                        <QuestionAnswer color="primary" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ),
                q => (<Divider key={`d${q.id}`} />)
              )}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2, display: 'flex', overflow: 'auto', flexDirection: 'column' }}>
            <PaperTitle>
              回答済みアンケート一覧
            </PaperTitle>
            <List>
              {Seq.mput(completes,
                q => (
                  <ListItem key={`i${q.id}`} alignItems="flex-start">
                    <ListItemText
                      primary={q.eproSchedule.eproQuestionnaire.label}
                      secondary={`回答日: ${format(parseISO(q.updatedAt), "yyyy/MM/dd")}`}
                      primaryTypographyProps={{ noWrap: true, gutterBottom: true }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        title="閲覧する"
                        edge="end"
                        component={Link}
                        to={`/${domain}/questionnaires/${q.id}/preview`}
                        size="large">
                        <Visibility color="primary" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ),
                q => (<Divider key={`d${q.id}`} />)
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
