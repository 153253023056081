import React, { useState, useContext } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Button, Avatar, TextField, Typography, Container, Alert, Box } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { AuthContext } from "../contexts/AuthContext";
import { ToastContext } from "../contexts/ToastContext";
import { isLoggedInPatient } from "../types/user";
import { useAccountLazyQuery } from "../types/graphql";

export function Login() {
  const navigate = useNavigate();
  const location = useLocation() as { state: { from: { pathname: string } } };
  const { user, setUser, domain } = useContext(AuthContext);
  const { popUpMessage } = useContext(ToastContext);
  const { from } = location.state || { from: { pathname: `/${domain}/` } };
  const [id, setId] = useState("");
  const [pin, setPin] = useState("");
  const [openValidationAlert, setOpenValidationAlert] = useState(false);

  const [getAccount] = useAccountLazyQuery({
    nextFetchPolicy: "network-only",
    onCompleted: d => {
      const a = d.eproAccount;
      if (a) {
        setUser({ id: a.id, clientId: a.clientId, pin: a.pin, email: a.email, phone: a.phone });
        navigate(from, { replace: true });
      } else {
        setOpenValidationAlert(true);
      }
    },
    onError: _ => popUpMessage("error", "問題が発生しました。しばらくしてからもう一度お試しください。")
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    getAccount({ variables: { pin, clientId: id } });
  };

  if (isLoggedInPatient(user!)) {
    const to = from.pathname === `/${domain}/login` ? `/${domain}/` : from.pathname;
    return <Navigate to={to} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={theme => ({ margin: 1, backgroundColor: theme.palette.secondary.main })}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">Sign in</Typography>
        { openValidationAlert && (
          <Box sx={{ marginTop: 2 }}>
            <Alert severity="error">
              {"ClientIDまたはPinが正しくありません。"}
            </Alert>
          </Box>
        )}
        <Box component="form" sx={{ width: '100%', marginTop: 1 }} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Client ID"
            name="client_id"
            autoComplete="current-client_id"
            autoFocus
            onChange={e => setId(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="pin_code"
            label="Pin Code"
            type="pin_code"
            id="pin_code"
            autoComplete="current-pin_code"
            onChange={e => setPin(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ marginTop: 2 }}
          >
            サインイン
          </Button>
        </Box>
      </Box>
    </Container>
  );
}