import React, { Dispatch } from 'react';
import { Box, Radio, RadioGroup, FormControlLabel } from "@mui/material";
import { Radio as RadioType } from "../../types/question";
import { DraggableSelectList } from "./DraggableSelectList";
import { QuestionsAction } from '../EditableQuestionnaire';
import { AnswerAction } from "../../pages/Answer";
import { RadioAnswer } from "../../types/answer";

type Props = { id: string, question: RadioType, dispatch: Dispatch<QuestionsAction> };
export const EditableRadio = (props: Props) => (<DraggableSelectList {...props} />);

export const PreviewRadio = ({ question, answer }: { question: RadioType, answer?: RadioAnswer }) => (
  <Box>
    <RadioGroup value={answer?.value}>
      {question.values.slice().sort((l, r) => l.seq - r.seq).map(v => (
        <FormControlLabel
          key={v.seq}
          label={v.label}
          value={v.seq}
          control={<Radio readOnly />}
        />
      ))}
    </RadioGroup>
  </Box>
);

type InputtableProps = { question: RadioType, answer: RadioAnswer, dispatch: Dispatch<AnswerAction> };

export const InputtableRadio = ({ question, answer, dispatch }: InputtableProps) => (
  <Box>
    <RadioGroup
      value={answer.value}
      onChange={e => dispatch({ type: "update", answer: {...answer, value: Number(e.target.value)}})}
    >
      {question.values.slice().sort((l, r) => l.seq - r.seq).map(v => (
        <FormControlLabel
          key={v.seq}
          label={v.label}
          value={v.seq}
          control={<Radio />}
        />
      ))}
    </RadioGroup>
  </Box>
);