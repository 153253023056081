import React from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import { EditableQuestionnaire } from "../components/EditableQuestionnaire";
import { useQuestionnaireQuery } from "../types/graphql";
import { Loading } from "../components/Loading";
import { DesignerError } from "../components/Error";

export function QuestionnaireEditor() {
  const { trial, questionnaireId } = useParams<{ trial: string, questionnaireId: string }>();
  const { loading, error, data } = useQuestionnaireQuery({ variables: { trial: trial!, id: questionnaireId! } });

  if (loading) {
    return <Loading open={loading} />;
  }

  if (error) {
    return <DesignerError error={error} />;
  }

  return (
    // <EditableQuestionnaire trial={trial!} questionnaire={data!} />
    <Container maxWidth="md">
      <EditableQuestionnaire trial={trial!} questionnaire={data!} />
    </Container>
  );
}