import React, { Dispatch } from 'react';
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { CheckBox } from "../../types/question";
import { DraggableSelectList } from "./DraggableSelectList";
import { QuestionsAction } from '../EditableQuestionnaire';
import { AnswerAction } from "../../pages/Answer";
import { CheckBoxAnswer } from "../../types/answer";
import * as Seq from "../../types/seq";

type EditableProps = { id: string, question: CheckBox, dispatch: Dispatch<QuestionsAction> };
export const EditableCheckBox = (props: EditableProps) => (<DraggableSelectList {...props} />);

export const PreviewCheckBox = ({ question, answer }: { question: CheckBox, answer?: CheckBoxAnswer }) => (
  <Box>
    {question.values.slice().sort((l, r) => l.seq - r.seq).map(v => (
      <Box key={v.seq}>
        <FormControlLabel
          label={v.label}
          control={
            <Checkbox readOnly checked={answer && answer.value.some(i => i === v.seq)} />
          }
        />
      </Box>
    ))}
  </Box>
);

type InputtableProps = { question: CheckBox, answer: CheckBoxAnswer, dispatch: Dispatch<AnswerAction> };

export const InputtableCheckBox = ({ question, answer, dispatch }: InputtableProps) => (
  <Box>
    {question.values.slice().sort((l, r) => l.seq - r.seq).map(v => (
      <Box key={v.seq}>
        <FormControlLabel
          label={v.label}
          control={
            <Checkbox
              checked={answer.value.some(i => i === v.seq)}
              onChange={e => dispatch({
                type: "update",
                answer: {...answer,
                  value: e.target.checked
                    ? Seq.uniq([...answer.value, v.seq])
                    : answer.value.filter(i => i !== v.seq)
                }
              })}
            />
          }
        />
      </Box>
    ))}
  </Box>
);