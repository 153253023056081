import React, { useState, useContext } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Button, Avatar, TextField, Typography, Container, Box, Alert } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { AuthContext } from "../contexts/AuthContext";
import { ToastContext } from "../contexts/ToastContext";
import * as AuthAPI from "../api/auth";
import { isLoggedInDesignerUser } from "../types/user";

export function DesignerLogin() {
  const navigate = useNavigate();
  const location = useLocation() as { state: { from: { pathname: string } } };
  const { user, setUser, domain } = useContext(AuthContext);
  const { popUpMessage } = useContext(ToastContext);
  const { from } = location.state || { from: { pathname: `/${domain}/designer` } };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openValidationAlert, setOpenValidationAlert] = useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const user = await AuthAPI.authenticateDesignerUser(email, password);
    if (user === "Unauthorized") {
      setOpenValidationAlert(true);
    } else if (user === "InternalServerError") {
      popUpMessage("error", "An error has occurred. Please try again after a while.");
    } else {
      setUser(user);
      navigate(from, { replace: true });
    }
  };

  if (isLoggedInDesignerUser(user)) {
    const to = from.pathname === `/${domain}/designer/login` ? `/${domain}/designer` : from.pathname;
    return <Navigate to={to} />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={theme => ({ margin: 1, backgroundColor: theme.palette.secondary.main })}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">Sign in</Typography>
        {openValidationAlert && (
          <Box sx={{ marginTop: 2 }}>
            <Alert severity="error">
              {"The password is incorrect or the email cannot be recognized."}
            </Alert>
          </Box>
        )}
        <Box component="form" sx={{ width: '100%', marginTop: 1 }} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="current-email"
            autoFocus
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ marginTop: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
