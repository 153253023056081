import { ReferenceDate as GRD } from "./graphql";

export type ReferenceDate = {
  id: string;
  isYearly: boolean;
  label: string;
}

export function from(value: Pick<GRD, "id" | "label" | "isYearly">): ReferenceDate {
  return {
    id: value.id,
    isYearly: value.isYearly,
    label: value.label
  };
}
