import React, { Dispatch } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
  Box,
  Divider,
  Card,
  CardContent,
  CardActions,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import {
  DragIndicator,
  ShortText,
  RadioButtonChecked,
  CheckBox,
  LinearScale,
  DeleteForeverOutlined,
  FileCopyOutlined
} from "@mui/icons-material";
import { Question, match, replaceWith, isQuestionKind } from "../types/question";
import { QuestionsAction } from "./EditableQuestionnaire";
import { EditableCheckBox, PreviewCheckBox } from "./questions/CheckBox";
import { EditableRadio, PreviewRadio } from "./questions/Radio";
import { EditableText, PreviewText } from "./questions/Text";
import { EditableSlider, PreviewSlider } from "./questions/Slider";
import { QuestionKind } from "../types/graphql";

type Props = {
  id: string,
  editable: boolean,
  question: Question,
  index: number,
  dispatch: Dispatch<QuestionsAction>
};
type EditableProps = Omit<Props, "editable" | "index">;

function TypeSelectBox({ id, question, dispatch }: EditableProps) {
  const change = (type: unknown) => {
    if (typeof(type) === "string" && isQuestionKind(type)) {
      dispatch({ type: "update", id, value: replaceWith(question, type)});
      return;
    }
    throw new Error(`Invalid Argument: ${type}`);
  }
  const renderValue = (type: unknown) => {
    if (typeof(type) !== "string" || !isQuestionKind(type)) {
      throw new Error(`Invalid Argument: ${type}`);
    }
    const select = (icon: JSX.Element, text: string) => (
      <MenuItem disableGutters>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </MenuItem>
    )
    if (type ===  QuestionKind.Text) {
      return select(<ShortText />, "Text");
    }
    if (type ===  QuestionKind.Radio) {
      return select(<RadioButtonChecked />, "Radio Button");
    }
    if (type ===  QuestionKind.Check) {
      return select(<CheckBox />, "Check Box");
    }
    if (type ===  QuestionKind.Slider) {
      return select(<LinearScale />, "Slider");
    }
  };
  return (
    <Select
      fullWidth
      displayEmpty
      sx={{ marginTop: "3px" }}
      value={question.type}
      onChange={e => change(e.target.value)}
      renderValue={renderValue}
    >
      <MenuItem value="text">
        <ListItemIcon>
          <ShortText />
        </ListItemIcon>
        <ListItemText primary="Text" />
      </MenuItem>
      <MenuItem value="radio">
        <ListItemIcon>
          <RadioButtonChecked />
        </ListItemIcon>
        <ListItemText primary="Radio Button" />
      </MenuItem>
      <MenuItem value="check">
        <ListItemIcon>
          <CheckBox />
        </ListItemIcon>
        <ListItemText primary="Check Box" />
      </MenuItem>
      <MenuItem value="slider">
        <ListItemIcon>
          <LinearScale />
        </ListItemIcon>
        <ListItemText primary="Slider" />
      </MenuItem>
    </Select>
  );
}

function EditingForm({ id, question, dispatch }: EditableProps) {
  return (
    <>
      <CardContent>
        <Box sx={{ marginBottom: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={9}>
              <TextField
                label="Question"
                required
                fullWidth
                value={question.label}
                onChange={e => dispatch({ type: "update", id, value: { ...question, label: e.target.value } })}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TypeSelectBox {...{id, question, dispatch}} />
            </Grid>
          </Grid>
        </Box>
        {match(question,
          _ => <EditableText />,
          q => <EditableRadio id={id} question={q} dispatch={dispatch} />,
          q => <EditableCheckBox id={id} question={q} dispatch={dispatch} />,
          q => <EditableSlider id={id} question={q} dispatch={dispatch} />
        )}
      </CardContent>
      <Divider variant="middle" />
      <CardActions>
        <Box sx={{ marginLeft: 'auto' }}>
          <Tooltip title="Copy">
            <IconButton aria-label="copy" onClick={() => dispatch({ type: "copy" })} size="large">
              <FileCopyOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              aria-label="delete"
              onClick={() => dispatch({ type: "remove", id })}
              size="large">
              <DeleteForeverOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      </CardActions>
    </>
  );
}

function PreviewForm({ question }: { question: Question }) {
  return (
    <CardContent>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle1">{question.label}</Typography>
      </Box>
      {match(question,
        _ => <PreviewText />,
        q => <PreviewRadio question={q} />,
        q => <PreviewCheckBox question={q}/>,
        q => <PreviewSlider question={q} />
      )}
    </CardContent>
  )
}

export function EditableQuestion({ editable, index, ...rest }: Props) {
  return (
    <Draggable draggableId={rest.id} index={index}>
      {provided => (
        <Card ref={provided.innerRef} {...provided.draggableProps}>
          <Box
            sx={{ display: "flex", justifyContent: "center" }}
            {...provided.dragHandleProps}
          >
            <DragIndicator
              fontSize="small"
              sx={{
                opacity: 0.3,
                transform: "rotate(90deg)",
                cursor: "move"
              }}
            />
          </Box>
          {editable
            ? <EditingForm {...rest} />
            : <PreviewForm question={rest.question} />
          }
        </Card>
      )}
    </Draggable>
  );
}
